import { createBrowserRouter, Navigate } from "react-router-dom";
import { UserRole } from "../enums/roles";
import {
    ABOUT_US,
    BADGE,
    BADGE_ID,
    BLOG,
    BLOG_ID,
    COMPETITION_ID,
    COMPETITIONS,
    COOKIE_DECLARATION,
    CREATE,
    DATASET_ID,
    DATASETS,
    EDIT,
    EVENTS,
    NOTEBOOK_ID,
    NOTEBOOKS,
    ORGANISATION_ID,
    ORGANISATIONS,
    PRIVACY_POLICY,
    PROFILE,
    PROVIDER,
    ROOT,
    SETTINGS,
    SIGN_IN,
    TERMS_AND_CONDITIONS,
    USER_ID,
} from "./routes";
// Layout
import MasterLayout from "../pages/master-layout";
import IntroPage from "../pages/intro-page";
import AuthGuard from "../auth-guard";
import OAuthRedirectPage from "../pages/oAuth-redirect";
import ErrorPage from "../pages/error-page";
// const MasterLayout = lazy(() => import("../pages/master-layout"));

// Static Pages
// import JoinUs from "../pages/join-page";
import PrivacyPolicy from "../pages/privacy-policy";
import CookieDeclaration from "../pages/cookie-declaration";
import TnC from "../pages/terms-and-conditions";

// Datasets
import DatasetPage from "../pages/dataset";
import CreateDatasetPage from "../pages/dataset/create-dataset";
import DatasetInfoPage from "../pages/dataset/dataset-info";
import EditDatasetPage from "../pages/dataset/edit-dataset";

// Notebooks
import NotebookPage from "../pages/notebook";
import NotebookViewPage from "../pages/notebook/notebook-view";

// Competitions
import CompetitionPage from "../pages/competition";
import CompetitionInfoPage from "../pages/competition/competition-info";
import CreateCompetitionPage from "../pages/competition/create-competition";
import EditCompetitionPage from "../pages/competition/edit-competition";

// Organizations
import OrganizationPage from "../pages/organization";
import OrganizationInfoPage from "../pages/organization/organization-info";

// Blogs
import BlogPage from "../pages/blog";
import ReadBlogPage from "../pages/blog/read-blog";
import CreateBlogPage from "../pages/blog/create-blog";
import EditBlogPage from "../pages/blog/edit-blog";

// Resources
// import ResourcesPage from "../pages/resources";
// import ResourceContent from "../pages/resources/resource-page";

// User Specific
import UserProfilePage from "../pages/user-profile";
import SettingsPage from "../pages/user-profile/settings";

// About us
import AboutUsPage from "../pages/about-us";

export const router = createBrowserRouter([
    {
        path: ROOT,
        element: <IntroPage />,
    },
    {
        path: ROOT,
        element: <MasterLayout />,
        children: [
            {
                path: `/${DATASETS}`,
                element: <DatasetPage />,
            },
            {
                path: `/${DATASETS}/:${DATASET_ID}`,
                element: <DatasetInfoPage />,
            },
            {
                path: `/${DATASETS}/${CREATE}`,
                element: (
                    <AuthGuard roles={["admin"]}>
                        <CreateDatasetPage />
                    </AuthGuard>
                ),
            },
            {
                path: `/${DATASETS}/${EDIT}/:${DATASET_ID}`,
                element: (
                    <AuthGuard roles={["admin"]}>
                        <EditDatasetPage />
                    </AuthGuard>
                ),
            },
            {
                path: `/${NOTEBOOKS}`,
                element: <NotebookPage />,
            },
            {
                path: `/${NOTEBOOKS}/:${NOTEBOOK_ID}`,
                element: <NotebookViewPage />,
            },
            {
                path: `/${PROFILE}/:${USER_ID}?`,
                element: (
                    <AuthGuard roles={[]}>
                        <UserProfilePage />
                    </AuthGuard>
                ),
            },
            {
                path: `/${PROFILE}/:${USER_ID}?/${BADGE}/:${BADGE_ID}?`,
                element: (
                    <AuthGuard roles={[]}>
                        <UserProfilePage />
                    </AuthGuard>
                ),
            },
            {
                path: `/${SETTINGS}`,
                element: (
                    <AuthGuard roles={[]}>
                        <SettingsPage />
                    </AuthGuard>
                ),
            },
            // {
            //     path: "/resources",
            //     element: <ResourcesPage />,
            // },
            // {
            //     path: "/resources/:page",
            //     element: <ResourceContent />,
            // },
            {
                path: `/${COMPETITIONS}`,
                element: <CompetitionPage />,
            },
            {
                path: `/${COMPETITIONS}/:${COMPETITION_ID}`,
                element: <CompetitionInfoPage />,
            },
            {
                path: `/${COMPETITIONS}/${CREATE}`,
                element: (
                    <AuthGuard roles={[UserRole.Admin]}>
                        <CreateCompetitionPage />
                    </AuthGuard>
                ),
            },
            {
                path: `/${COMPETITIONS}/${EDIT}/:${COMPETITION_ID}`,
                element: (
                    <AuthGuard roles={[UserRole.Admin]}>
                        <EditCompetitionPage />
                    </AuthGuard>
                ),
            },
            {
                path: `/${ORGANISATIONS}`,
                element: (
                    <AuthGuard roles={[UserRole.Admin]}>
                        <OrganizationPage />
                    </AuthGuard>
                ),
            },
            {
                path: `/${ORGANISATIONS}}/:${ORGANISATION_ID}`,
                element: (
                    <AuthGuard roles={[UserRole.Admin]}>
                        <OrganizationInfoPage />
                    </AuthGuard>
                ),
            },
            {
                path: `/${BLOG}`,
                element: <BlogPage />,
            },
            {
                path: `/${BLOG}/:${BLOG_ID}`,
                element: <ReadBlogPage />,
            },
            {
                path: `/${BLOG}/${CREATE}`,
                element: (
                    <AuthGuard roles={[UserRole.Admin]}>
                        <CreateBlogPage />
                    </AuthGuard>
                ),
            },
            {
                path: `/${BLOG}/${EDIT}/:${BLOG_ID}`,
                element: (
                    <AuthGuard roles={[UserRole.Admin]}>
                        <EditBlogPage />
                    </AuthGuard>
                ),
            },
            // {
            //     path: `/${EVENTS}`,
            //     element: <JoinUs />,
            // },
            // {
            //     path: "/join",
            //     element: <Navigate to={`/${EVENTS}`} replace />,
            // },
            {
                path: `/${EVENTS}`,
                element: <Navigate to={`/`} replace />,
            },
            {
                path: "/join",
                element: <Navigate to={`/`} replace />,
            },
            {
                path: `/${PRIVACY_POLICY}`,
                element: <PrivacyPolicy />,
            },
            {
                path: `/${COOKIE_DECLARATION}`,
                element: <CookieDeclaration />,
            },
            {
                path: `/${TERMS_AND_CONDITIONS}`,
                element: <TnC />,
            },
            {
                path: `/${ABOUT_US}`,
                element: <AboutUsPage />,
            },
        ],
    },
    {
        path: `${SIGN_IN}/:${PROVIDER}?`,
        element: <OAuthRedirectPage />,
    },
    {
        path: "*",
        element: <ErrorPage />,
    },
]);
