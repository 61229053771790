import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

// import: assets
import datasetIcon from "../../assets/icons/notebook.svg";
import competitionsIcon from "../../assets/icons/competitions.svg";

import googleCollabs from "../../assets/logos/googleCollabs.png";
import python from "../../assets/logos/python.png";
import gitIcon from "../../assets/logos/github.png";

import maximize from "../../assets/icons/maximize.png";
import minimize from "../../assets/icons/minimize.png";
import report from "../../assets/icons/report.png";

// import: styles
import "./index.scss";

// import: constants
import { COMPETITIONS, DATASETS, NOTEBOOK_ID } from "../../router/routes";
// import: enums
// import: types
// import: utils
import { HandleError } from "../../errors/handler";
import { timeElapsed } from "../../utils/helper-methods";

// import: data
// import: store
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import {
    loadNotebookInfoById,
    setNotebookInfo,
} from "../../store/slices/notebook-slice";

// import: api
// import: config
// import: components
import LoadingSpinner from "../../components/loading-spinner";
import Button from "react-bootstrap/Button";
import ErrorAlert from "../../components/error-alert";
import AlertNotebookModal from "../../components/alert-modal";
import Modal from "react-bootstrap/Modal";
import NotebookView from "../../components/notebookViewerImpl";
import NB4 from "../../components/notebook-view";
import CategoryIcons from "../../components/category-icons";

export default function NotebookViewPage() {
    const dispatch = useAppDispatch();
    const { [NOTEBOOK_ID]: _id } = useParams();

    const user = useAppSelector((s) => s.auth.user);

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState("");

    const notebooks = useAppSelector((s) => s.notebook.approvedData.data);
    const notebookInfo = useAppSelector((store) => store.notebook.notebookInfo);
    const notebook = useMemo(() => {
        const filteredNotebooks = notebooks.filter((c) => c._id === _id);
        if (!notebookInfo && filteredNotebooks.length > 0) return filteredNotebooks[0];
        return notebookInfo;
    }, [_id, notebooks, notebookInfo]);

    const [launchError, setLaunchError] = useState("");

    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [maximized, setMaximized] = useState<boolean>(false);
    const [launch, setLaunch] = useState<boolean>(false);

    const loadNotebook = useCallback(async (_id: string) => {
        try {
            setLoading(true);
            dispatch(loadNotebookInfoById({ _id }));
        } catch (error) {
            setError(HandleError(error)[""]);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (_id) loadNotebook(_id);

    }, [_id, loadNotebook, user]);

    useEffect(() => {
        return (() => {
            dispatch(setNotebookInfo(undefined))
        })
    }, [dispatch]);

    // const star = async () => {
    //     try {
    //         if (!notebook) return;
    //         setChangingStarStatus(true);
    //         const response = await dispatch(
    //             starNotebook({
    //                 _id: notebook._id,
    //                 starStatus: !notebook.stared,
    //             })
    //         ).unwrap();
    //         setNotebook({ ...notebook, stared: response.response });
    //     } catch (error) {
    //         setError(HandleError(error)[""]);
    //     } finally {
    //         setChangingStarStatus(false);
    //     }
    // };

    // const deleteNb = async (id: string) => {
    //     try {
    //         if (window.confirm("Are you sure?")) {
    //             setLoading(true);
    //             await dispatch(deleteNotebook(id)).unwrap();
    //         }
    //     } catch (error) {
    //         setError(HandleError(error)[""]);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const downloadFile = () => {
        if (notebook?.githubUrl) {
            fetch(notebook?.githubUrl).then(resp => resp.json()).then((data: any) => {
                if (data.download_url) {
                    fetch(data.download_url).then(resp => resp.json()).then((data: any) => {
                        let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
                        let downloadAnchorNode = document.createElement('a');
                        downloadAnchorNode.setAttribute("href", dataStr);
                        downloadAnchorNode.setAttribute("download", notebook.title + ".ipynb");
                        document.body.appendChild(downloadAnchorNode); // required for firefox
                        downloadAnchorNode.click();
                        downloadAnchorNode.remove();
                    }).catch(err => {
                        setLaunchError("Error launching notebook.");
                    });
                }
            }).catch(err => {
                setLaunchError("Error fetching notebook link.");
            });
        }
    }

    const launchCollab = () => {
        if (notebook?.githubUrl) {
            fetch(notebook?.githubUrl).then(resp => resp.json()).then((data: any) => {
                window.open(data.html_url.replace("github", "githubtocolab"), '_blank', 'noopener')?.focus();
            }).catch(err => {
                setLaunchError("Error launching notebook.");
            });
        }
    }

    const launchGit = () => {
        if (notebook?.githubUrl) {
            fetch(notebook?.githubUrl).then(resp => resp.json()).then((data: any) => {
                window.open(data.html_url.replace("github.com", "github.dev"), '_blank', 'noopener')?.focus();
            }).catch(err => {
                setLaunchError("Error fetching notebook link.");
            });
        }
    }

    const tabOptions = () => {
        return (
            <div className="screen-menu-cover">
                <div className="screen-menu">
                    <span className={`screen-menu-item mb-md-2 me-2 me-md-0 mb-0 ${maximized ? "selected" : ""}`}
                        onClick={() => setMaximized(true)}>
                        <img alt="" src={maximize} />
                    </span>
                    <span className={`screen-menu-item mb-md-2 me-2 me-md-0 mb-0 ${maximized ? "" : "selected"}`}
                        onClick={() => setMaximized(false)}>
                        <img alt="" src={minimize} />
                    </span>
                    {user?.isVerified && <span className="screen-menu-item"
                        onClick={() => setShowAlert(true)}>
                        <img alt="" className="mb-1" src={report} />
                    </span>}
                </div>
            </div>
        )
    }

    return (
        <div className="page-container lg-box">
            <ErrorAlert show={error ? true : false} errorMessage={error} />

            <div className="d-flex flex-wrap mb-2">
                <div className="page-heading-container">
                    <div className="text-color-tertiary text-xs d-flex">
                        <span className="me-2">
                            {notebook?.createdBy?.fullName ?? ""}
                        </span>
                        {notebook?.updatedAt ? <li> Uploaded {timeElapsed(notebook.updatedAt, new Date()) + " ago"}</li> : undefined}
                    </div>
                    <span className="text-3xl mb-2">{notebook?.title}</span>
                    <div className="d-flex">
                        {notebook?.competition && notebook.competition?.type ? <span className="d-flex align-items-center me-3">
                            <img className="me-2" width={12} alt="" src={CategoryIcons(notebook.competition?.type)} />
                            <span className="text-capitalize text-xs text-color-tertiary">{notebook.competition?.type}</span>
                        </span> : undefined}
                        {notebook?.dataset && notebook.dataset?.type ? <span className="d-flex align-items-center">
                            <img className="me-2" width={12} alt="" src={CategoryIcons(notebook.dataset?.type)} />
                            <span className="text-capitalize text-xs text-color-tertiary">{notebook.dataset?.type}</span>
                        </span> : undefined}
                    </div>

                    <div className="d-flex mt-4">
                        <div className="notebook-tokens">
                            {notebook?.epsilon ? <div className="glazed-token flex-column">
                                <span className="mb-1 text-xs">EPSILON</span>
                                <span className="number text-xl">{parseFloat(notebook?.epsilon.toFixed(4))}</span>
                            </div> : undefined}

                            {notebook?.delta ? <div className="glazed-token flex-column">
                                <span className="mb-1 text-xs">DELTA</span>
                                <span className="number text-xl">{parseFloat(notebook?.delta.toFixed(4))}</span>
                            </div> : undefined}
                        </div>
                        {/* {user ?
                            <FontAwesomeIcon
                                onClick={() => star()}
                                className="px-2 cursor-pointer"
                                spin={changingStarStatus}
                                icon={ changingStarStatus ? faSpinner : notebook.stared ? faStar : faStarOutline }
                            />
                            : undefined} */}

                        {/* <ShareButton
                            url={window.location.href}
                            subject={""}
                            description={notebook.title ?? ""}
                            slim={true}
                        /> */}
                    </div>
                </div>
                <div>
                    {notebook?.dataset ? <div className="glazed-card-cover radius-base reference-name-holder mb-2">
                        <div className="link-item glazed-card d-flex flex-column radius-base px-3 pt-2 pb-3">
                            <span className="mb-2 text-color-tertiary text-xs">Dataset Notebook</span>
                            <span className="reference-name text-sm">
                                <img src={datasetIcon} height={20} width={20} alt="" className="me-2" />
                                <a style={{ color: 'unset', textDecoration: 'none' }} href={`/${DATASETS}/` + notebook.dataset?._id}>
                                    {notebook?.dataset?.title}
                                </a>
                            </span>
                        </div>
                    </div> : notebook?.competition ?
                        <div className="glazed-card-cover radius-base reference-name-holder mb-2">
                            <div className="link-item glazed-card d-flex flex-column radius-base px-3 pt-2 pb-3">
                                <span className="mb-2 text-color-tertiary text-xs">Competition Notebook</span>
                                <span className="reference-name text-sm">
                                    <img src={competitionsIcon} height={20} width={20} alt="" className="me-2" />
                                    <a style={{ color: 'unset', textDecoration: 'none' }} href={`/${COMPETITIONS}/` + notebook.competition?._id}>
                                        {notebook?.competition?.title}
                                    </a>
                                </span>
                            </div>
                        </div> : <></>}

                    {user && notebook?.githubUrl ?
                        <div className="pt-3 mb-3"><Button onClick={() => setLaunch(true)} variant="primary" className="rounded-pill me-auto mb-auto ms-2">
                            Launch Notebook
                            <svg width="10" height="10" className="link-icon" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.833008 9.16671L9.16634 0.833374M9.16634 0.833374H0.833008M9.16634 0.833374V9.16671" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Button> </div> : <></>}
                </div>
            </div>
            <div className={maximized ? "back-cover" : " d-none"}></div>
            {notebook ? (
                <div className={`${maximized ? "notebook-maximized" : "onFit"}`}>
                    <div className={`info-page-tab glazed-card-cover`}>
                        {tabOptions()}
                        <div className={`info-page-tab-content ${maximized ? "glazed-card" : "bg-tertiary"}`}>
                            {notebook.notebookJson?.nbformat && (() => {
                                switch (notebook.notebookJson?.nbformat) {
                                    case 3:
                                        return <NotebookView source={notebook.notebookJson} className="" />
                                    case 4:
                                        return <NB4 source={notebook.notebookJson} className="" />
                                    case 5:
                                        return <NB4 source={notebook.notebookJson} className="" />
                                    default:
                                        return <p>
                                            Unsupported Notebook format: this notebook format is not corrently supported
                                        </p>;
                                }
                            })()
                            }
                        </div>
                    </div>
                </div>
            ) : undefined}
            <LoadingSpinner show={loading} text="Loading Notebook" />
            {notebook && user?.isVerified ? <AlertNotebookModal show={showAlert} setShow={setShowAlert} notebookID={notebook._id} /> : undefined}

            <Modal className="login-modal" show={launch} centered onHide={() => setLaunch(false)}>
                <Modal.Header className="border-0 p-3 pb-0" closeButton>
                    <p className="mb-0 text-lg">Launch Notebook from</p>
                </Modal.Header>
                <Modal.Body className="p-3 pt-2">
                    <p className="text-color-secondary text-xs mb-4">Launch existing notebook from other platforms.</p>

                    <div className="form-external-brand-links text-color-secondary">
                        <div className="me-5">
                            <div className="brand-image-cover mx-auto">
                                <img alt="" className="feature-icon" src={googleCollabs}
                                    onClick={() => launchCollab()}
                                />
                            </div>
                            <p className="mt-2 mb-0 text-xs">Google collabs</p>
                        </div>
                        <div className="me-5">
                            <div className="brand-image-cover mx-auto">
                                <img alt="" className="feature-icon" src={gitIcon}
                                    onClick={() => launchGit()}
                                />
                            </div>
                            <p className="mt-2 mb-0 text-xs">Github</p>
                        </div>
                        <div>
                            <div className="brand-image-cover mx-auto">
                                <img alt="" className="feature-icon" src={python}
                                    onClick={() => downloadFile()}
                                />
                            </div>
                            <p className="mt-2 mb-0 text-xs">Download .ipynb</p>
                        </div>
                    </div>
                    <div className="text-xs mt-3 text-color-red-normal">
                        {launchError}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
