import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { renderToString } from 'react-dom/server'

// import: assets
// import: styles
import "./index.scss";

// import: constants
import { BLOG, BLOG_ID, EDIT, ERROR } from "../../router/routes";
// import: enums
import { UserRole } from "../../enums/roles";
import { SectionType } from "../../enums/blog-section";

// import: types
import { IBlog } from "../../types/blog-types";

// import: utils
import { convertDate } from "../../utils/helper-methods";
import { HandleError } from "../../errors/handler";

// import: data
// import: store
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import { deleteBlog, loadBlogInfoById, setBlogInfo } from "../../store/slices/blog-slice";

// import: api
// import: config
// import: components
import LoadingSpinner from "../../components/loading-spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from 'react-helmet-async';
import ErrorAlert from "../../components/error-alert";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import vscDarkPlus from '../../components/notebook-view/vsc-dark-plus';

type BlogProps = {
  data?: IBlog;
};

function ReadBlogPage(props: BlogProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { [BLOG_ID]: _id } = useParams();

  const user = useAppSelector((s) => s.auth.user);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const blogPosts = useAppSelector((s) => s.blog.pageData.data);
  const postInfo = useAppSelector((store) => store.blog.blogInfo);
  const post = useMemo(() => {
    const filteredBlogs = blogPosts.filter((c) => c._id === _id);
    if (!postInfo && filteredBlogs.length > 0) return filteredBlogs[0];
    return postInfo;
  }, [_id, blogPosts, postInfo]);

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [completedRewrite, setCompletedRewrite] = useState<boolean>(false);

  useEffect(() => {
    if (!props.data) {
      if (_id) {
        setLoading(true);
        dispatch(loadBlogInfoById({ _id }))
          .unwrap()
          .then(() => { })
          .catch((error) => {
            setError(HandleError(error)[""]);
            navigate(`/${ERROR}`)
          })
          .finally(() => setLoading(false));
      } else {
        dispatch(setBlogInfo(undefined));
        setError("No post to be found.");
      }
    }
  }, [_id]);

  useEffect(() => {
    return (() => {
      dispatch(setBlogInfo(undefined))
    })
  }, [dispatch]);

  useEffect(() => {
    if (props.data) {
      dispatch(setBlogInfo(props.data));
    }
  }, [props.data]);

  const deleteSelectedPost = async () => {
    try {
      setLoading(true);
      if (post?._id) {
        await dispatch(deleteBlog(post?._id)).unwrap();
        navigate(`/${BLOG}`);

      } else setError("Error deleting post: unknown ID");

    } catch (error) {
      setError(HandleError(error)[""]);
    } finally {
      setLoading(false);
      setConfirmDelete(false);
    }
  };

  const replaceWithCode = (elem: Element) => {
    const highlighted = <SyntaxHighlighter children={elem.innerHTML} language='python' style={vscDarkPlus as any} />;

    const toString = renderToString(highlighted)
    return toString
  }

  useEffect(() => {
    if (post && !completedRewrite) {
      const allPre = document.querySelectorAll("pre");
      allPre.forEach((pre, id) => {
        pre.innerHTML = replaceWithCode(pre);
        if (id === allPre.length - 1) setCompletedRewrite(true);
      })
    }
  }, [post])

  const renderImageSection = (section: any) => {
    return section.content ? (
      <div className='post-section my-4' key={"img" + section.id}>
        <img src={section.content.result || section.content} alt='' className="section-image" />
        <span className='text-sm text-color-tertiary'>{section.author}</span>
      </div>
    ) : <></>;
  }

  const renderColumnImageSection = (section: any) => {
    return (
      <div className='d-flex column-block mb-4 flex-column flex-lg-row' key={"colimg" + section.id}>
        {section?.content.leftBlock ?
          <div className="post-section post-column text-sm left-block w-100 w-lg-50"
            dangerouslySetInnerHTML={{ __html: section.content.leftBlock }}
          ></div> : <></>
        }
        {section?.content.rightBlock ?
          <div className='post-section post-column w-100 w-lg-50'>
            <img src={section.content.rightBlock.result || section.content.rightBlock} alt='' className="section-image" />
            <span className='text-sm text-color-tertiary'>{section.author}</span>
          </div> : <></>
        }
      </div>
    )
  }

  const renderImageColumnSection = (section: any) => {
    return (
      <div className='d-flex column-block mb-4 flex-column flex-lg-row' key={"imgcol" + section.id}>
        {section?.content.leftBlock ?
          <div className='post-section post-column w-100 w-lg-50 left-block'>
            <img src={section.content.leftBlock.result || section.content.leftBlock} alt='' className="section-image" />
            <span className='text-sm text-color-tertiary'>{section.author}</span>
          </div> : <></>
        }
        {section?.content.rightBlock ?
          <div className="post-section post-column text-sm w-100 w-lg-50"
            dangerouslySetInnerHTML={{ __html: section.content.rightBlock }}
          ></div> : <></>
        }
      </div>
    )
  }

  const renderColumnsSection = (section: any) => {
    return (
      <div className='d-flex column-block mb-4 flex-column flex-lg-row' key={"col" + section.id}>
        {section?.content.leftBlock ?
          <div className="post-section post-column text-sm w-100 w-lg-50 left-block"
            dangerouslySetInnerHTML={{ __html: section.content.leftBlock }}
          ></div> : <></>
        }
        {section?.content.rightBlock ?
          <div className="post-section post-column text-sm w-100 w-lg-50"
            dangerouslySetInnerHTML={{ __html: section.content.rightBlock }}
          ></div> : <></>
        }
      </div>
    )
  }
  const renderQuoteSection = (section: any) => {
    return (
      <div className='quote-section mb-4' key={"quote" + section.id}>
        <div className="text-3xl text-spacious mb-21">
          {section.content}
        </div>
        <span className='text-lg'>{"- " + section.author}</span>
      </div>
    );
  }

  const renderParaSection = (section: any) => {
    return (
      <div key={"para" + section.id}>
        {section?.content ?
          <div className="post-section mb-4 text-sm"
            dangerouslySetInnerHTML={{ __html: section.content }}
          ></div> : <></>
        }
      </div>
    );
  }

  return (
    <div className="page-container blog-page sxs-box">
      {post ? <Helmet prioritizeSeoTags>
        <title>{post?.title}</title>
        <meta charSet="utf-8" />
        <meta name="description" content={post?.subTitle} />
        <link rel="canonical" href={process.env.REACT_APP_URL ? `${process.env.REACT_APP_URL}/blog/${post._id}` :
          `https://www.antigranular.com/blog/${post._id}`} />
      </Helmet> : <></>}

      <ErrorAlert errorMessage={error} show={error ? true : false} />

      {post ?
        <div className="d-flex flex-column mb-21">
          <div className='d-flex align-items-start'>
            {post.createdAt ? <span className="text-sm endDate2 mb-2 pe-2">
              {post && convertDate(post.createdAt.toUTCString())}
            </span> : <></>}
            {post.readTime ? <li className="text-sm text-color-tertiary me-3">{post?.readTime}</li> : <></>}

            {user?.roles.includes(UserRole.Admin) ?
              <FontAwesomeIcon icon={faEdit} className="feature-icon"
                title="Edit" size="sm"
                onClick={() => navigate(`/${BLOG}/${EDIT}/` + post?._id)} />
              : <></>}
          </div>

          <p className="mb-2 pb-1 text-heading-small vertical-glaze-text-2">{post?.title}</p>

          {post?.createdBy ? <div className='text-lg'>
            {"By " + post?.updatedBy?.fullName || post?.createdBy.fullName}
          </div> : <></>}
        </div> : <></>
      }

      {post && user?.roles?.includes(UserRole.Admin) ? (
        <div className="d-none d-sm-block">
          <Button variant="secondary" className="mb-3 rounded-pill" onClick={() => setConfirmDelete(true)}>
            Delete Post
          </Button>
        </div>
      ) : undefined}

      {post ? (
        <>
          {post?.overview ?
            <div className="post-overview"
              dangerouslySetInnerHTML={{
                __html: post?.overview ?? "",
              }}
            ></div> : <></>
          }

          <div className='post-content border-top pt-3 text-spacious'>
            {
              post?.article?.map((section: any) => {
                switch (section.type) {
                  // The only difference between these two is "Out[...]:"
                  case SectionType.Image:
                    return renderImageSection(section);
                  case SectionType.Columns:
                    return renderColumnsSection(section);
                  case SectionType.Quote:
                    return renderQuoteSection(section);
                  case SectionType.Para:
                    return renderParaSection(section);
                  case SectionType.ImageColumn:
                    return renderImageColumnSection(section);
                  case SectionType.ColumnImage:
                    return renderColumnImageSection(section);
                  default:
                    return undefined;
                }
              })
            }
          </div>

        </>
      ) : undefined}

      <Modal show={confirmDelete} className="login-modal" centered onHide={() => setConfirmDelete(false)}>
        <Modal.Header className="border-0 p-3" closeButton>
          <p className="lead text-center">Are you sure?</p>
        </Modal.Header>

        <Modal.Body className=" pt-0">
          <p className=" mb-5 mt-4">Are you sure you want to delete {post?.title || "this post"}?</p>

          <div className="d-flex">
            <Button
              onClick={() => setConfirmDelete(false)}
              className="rounded-pill ms-auto me-4"
              variant="secondary"
            >
              Close
            </Button>
            <Button
              onClick={deleteSelectedPost}
              className="rounded-pill"
              variant="primary"
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <LoadingSpinner show={loading} text="Loading..." />
    </div>
  )
}

export default ReadBlogPage;
