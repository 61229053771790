import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// import: assets
import {
  faArrowLeft,
  faAdd,
  faClose,
  faAngleDown,
  faAngleUp
} from "@fortawesome/free-solid-svg-icons";

// import: styles
// import: constants
import { BLOG_ID } from "../../router/routes";

// import: enums
import { SectionType, PageRefType } from "../../enums/blog-section";

// import: types
import {
  IBlog,
  IBlogParaSection,
  IBlogColumnsSection,
  IBlogImageSection,
  IBlogQuoteSection,
  IUpdateBlogPayload,
  IBlogImageColumnSection,
  IBlogColumnImageSection
} from "../../types/blog-types";

// import: utils
import { HandleError } from "../../errors/handler";

// import: data
// import: store
import { useAppDispatch } from "../../store/store-hooks";
import { showInfoModal } from "../../store/slices/modal-states-slice";
import { updateBlog } from "../../store/slices/blog-slice";

// import: api
import BlogApi from "../../api/blog-api";

// import: config
// import: components
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RichTextEditor from "../../components/richtext-editor";
import FileInput from "../../components/input/file-input";
import Input from "../../components/input/input";
import LoadingSpinner from "../../components/loading-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputSelect from "../../components/input/input-select";
import ErrorAlert from "../../components/error-alert";
import ReadBlogPage from "./read-blog";
import ReadObliviousBlogPage from "./read-oblivious-blog";
import Checkbox from "../../components/checkbox";

const emptyPage: PageRefType = {
  title: "",
  url: "",
  openInNewTab: false,
}

function UpdateBlogPage() {
  const { [BLOG_ID]: _id } = useParams();

  const [post, setPost] = useState<IBlog>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  //control refs
  const formRef = useRef<HTMLFormElement>(null);
  const titleInputRef = useRef<HTMLInputElement>(null);
  //form states
  const [formErrors, setFormErrors] = useState<any>({});
  const [fetchError, setFetchError] = useState<string>();

  const [title, setTitle] = useState<string>("");
  const [readTime, setReadTime] = useState<string>("");
  const [subTitle, setSubTitle] = useState<string>("");
  const [overview, setOverview] = useState<string>("");
  const [banner, setBanner] = useState<File>();
  const [type, setType] = useState<string>("");
  const [site, setSite] = useState<string>("");
  const [article, setArticle] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const [tags, setTags] = useState<string>("");
  const [nextBlog, setNextBlog] = useState<PageRefType>({ ...emptyPage });
  const [previousBlog, setPreviousBlog] = useState<PageRefType>({ ...emptyPage });

  const blogTypes = [
    {
      "label": "Event",
      "value": "large"
    },
    {
      "label": "Info",
      "value": "small"
    },
    {
      "label": "News",
      "value": "medium"
    }
  ];

  const hostingSite = [
    {
      "label": "Oblivious",
      "value": "Oblivious"
    },
    {
      "label": "Antigranular",
      "value": "Antigranular"
    }
  ];

  const arrayMove = (old_index: number, new_index: number) => {
    let holder = [...article];
    if (new_index >= holder.length) {
      var k = new_index - holder.length + 1;
      while (k--) {
        holder.push(undefined);
      }
    }
    holder.splice(new_index, 0, holder.splice(old_index, 1)[0]);
    setArticle(holder);
  };

  const createBlogObject = () => {
    if (post && validateForm()) {
      const blog = {
        _id: post?._id,
        title: title,
        subTitle: subTitle,
        readTime: readTime,
        overview: overview,
        type: type,
        site: site,
        createdBy: post?.createdBy,
        updatedBy: post?.updatedBy,
        createdAt: post?.createdAt,
        updatedAt: post?.updatedAt,
        article: article,
      }

      setPost(blog);
      setShowPreview(true);
    }
  };

  useEffect(() => {
    if (!post && _id) {
      setLoading(true);
      BlogApi.get(_id)
        .then((ds) => {
          setPost(ds);
        })
        .catch((error) => {
          setFetchError(HandleError(error)[""]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (post) {
      setTitle(post.title ?? "");
      setReadTime(post.readTime ?? "");
      setSubTitle(post.subTitle ?? "");
      setOverview(post.overview ?? "");
      setType(post.type);
      setTags(post.tags ?? "");
      setPreviousBlog(post.previousBlog ?? { ...emptyPage });
      setNextBlog(post.nextBlog ?? { ...emptyPage })
      setSite(post.site);
      setArticle(post.article)
    }
  }, [post]);


  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (validateForm() && post) {
        setLoading(true);
        const obj = {
          _id: post._id,
          title,
          subTitle,
          overview,
          banner,
          type,
          readTime,
          site,
          previousBlog,
          nextBlog,
          tags,
          article: JSON.stringify(article),
        } as IUpdateBlogPayload;

        await dispatch(updateBlog(obj)).unwrap();
        dispatch(
          showInfoModal({
            title: "Updated",
            message: "Bolog post updated succcessfully.",
          })
        );
        // resetForm();
      }
    } catch (error) {
      setFormErrors(HandleError(error));
    } finally {
      setLoading(false);
    }
  };


  const validateForm: () => boolean = () => {
    setFormErrors({});
    const errors: any = {};

    if (!title) {
      errors["title"] = "Please write dataset title.";
    }

    if (!subTitle) {
      errors["subTitle"] = "Please write subtitle.";
    }

    if (!readTime) {
      errors["readTime"] = "Please give reading time.";
    }

    if (!article || article.length === 0) {
      errors["article"] = "Please add some content in blog.";
    }

    if (!type) {
      errors["type"] = "Please select blog type.";
    }

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    }
    return true;
  };


  const convertToBase64 = function (file: File) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();
      reader.onloadend = function (e) {
        if (e.target) {
          resolve({
            fileName: file.name,
            result: e.target.result,
            error: e.target.error
          });
        }
      };
      reader.readAsDataURL(file);
    })
  }

  const addParaElement = () => {
    let holder = [...article];
    let index = article.length;
    let paraElement = {
      id: "Section " + index,
      type: SectionType.Para,
      content: ""
    } as IBlogParaSection

    holder.push(paraElement);
    setArticle(holder);
  };

  const addQuoteElement = () => {
    let holder = [...article];
    let index = article.length;
    let paraElement = {
      id: "Section " + index,
      type: SectionType.Quote,
      author: "",
      content: ""
    } as IBlogQuoteSection

    holder.push(paraElement);
    setArticle(holder);
  };

  const addColumnsElement = () => {
    let holder = [...article];
    let index = article.length;
    let paraElement = {
      id: "Section " + index,
      type: SectionType.Columns,
      content: {
        leftBlock: "",
        rightBlock: "",
      }
    } as IBlogColumnsSection

    holder.push(paraElement);
    setArticle(holder);
  };

  const addImageColumnElement = () => {
    let holder = [...article];
    let index = article.length;
    let paraElement = {
      id: "Section " + index,
      type: SectionType.ImageColumn,
      author: "",
      content: {
        leftBlock: "",
        rightBlock: "",
      }
    } as IBlogImageColumnSection

    holder.push(paraElement);
    setArticle(holder);
  };

  const addColumnImageElement = () => {
    let holder = [...article];
    let index = article.length;
    let paraElement = {
      id: "Section " + index,
      type: SectionType.ColumnImage,
      author: "",
      content: {
        leftBlock: "",
        rightBlock: "",
      }
    } as IBlogColumnImageSection

    holder.push(paraElement);
    setArticle(holder);
  };

  const addImageElement = () => {
    let holder = [...article];
    let index = article.length;
    let paraElement = {
      id: "Section " + index,
      type: SectionType.Image,
      author: "",
      content: ""
    } as IBlogImageSection

    holder.push(paraElement);
    setArticle(holder);
  };

  const resetForm = () => {
    setTitle("");
    setSubTitle("");
    setOverview("");
    setBanner(undefined);
    setReadTime("");
    setFormErrors({});
    setType("");
    setTags("");
    setPreviousBlog({ ...emptyPage });
    setNextBlog({ ...emptyPage });
    setArticle([]);
    titleInputRef?.current?.focus();
  };

  return (
    <div>
      <ErrorAlert
        show={fetchError ? true : false}
        errorMessage={fetchError}
      />

      {post ? (
        <>
          <div className="d-flex flex-row align-content-center">
            <FontAwesomeIcon
              size="lg"
              className="mx-3 cursor-pointer"
              onClick={() => navigate(-1)}
              icon={faArrowLeft}
            />
            <h4>Update Blog</h4>
          </div>

          <div className="bg-primary">
            <Form
              onReset={resetForm}
              ref={formRef}
              noValidate
              onSubmit={onSubmit}
            >
              <Form.Group>
                <Input
                  ref={titleInputRef}
                  autoFocus
                  focusOnError={true}
                  label="Title"
                  placeholder="Blog Title"
                  value={title}
                  onChange={(e) => {
                    if (formErrors["title"]) {
                      delete formErrors.title;
                      setFormErrors(formErrors);
                    }
                    setTitle(e.target.value);
                  }}
                  error={formErrors["title"]}
                />
              </Form.Group>

              <Form.Group className="mt-3">
                <Input
                  label="Subtitle"
                  focusOnError={true}
                  placeholder="Blog Subtitle"
                  value={subTitle}
                  onChange={(e) => {
                    if (formErrors["subTitle"]) {
                      delete formErrors.subTitle;
                      setFormErrors(formErrors);
                    }
                    setSubTitle(e.target.value);
                  }}
                  error={formErrors["subTitle"]}
                />
              </Form.Group>

              <Form.Group className="mt-3">
                <Input
                  label="Reading Time"
                  focusOnError={true}
                  placeholder="Reading Time"
                  value={readTime}
                  onChange={(e) => {
                    if (formErrors["readTime"]) {
                      delete formErrors.readTime;
                      setFormErrors(formErrors);
                    }
                    setReadTime(e.target.value);
                  }}
                  error={formErrors["readTime"]}
                />
              </Form.Group>


              <Form.Group className="mt-3">
                <Input
                  label="Tags"
                  focusOnError={true}
                  placeholder="give comma separated tags"
                  value={tags}
                  onChange={(e) => {
                    if (formErrors["tags"]) {
                      delete formErrors.tags;
                      setFormErrors(formErrors);
                    }
                    setTags(e.target.value);
                  }}
                  error={formErrors["tags"]}
                />
              </Form.Group>

              <p className="mt-3 mb-2 text-xs">Previous Blog</p>
              <Form.Group className="mb-4">
                <Row>
                  <Col lg={5}>
                    <Input
                      label="Title"
                      focusOnError={true}
                      placeholder="Previous blog title"
                      value={previousBlog?.title}
                      onChange={(e) => {
                        if (formErrors["prevTitle"]) {
                          delete formErrors.prevTitle;
                          setFormErrors(formErrors);
                        }
                        setPreviousBlog({ ...previousBlog, title: e.target.value });
                      }}
                      error={formErrors["prevTitle"]}
                    />
                  </Col>

                  <Col lg={5}>
                    <Input
                      label="URL"
                      focusOnError={true}
                      placeholder="Previous blog title"
                      value={previousBlog?.url}
                      onChange={(e) => {
                        if (formErrors["prevURL"]) {
                          delete formErrors.prevURL;
                          setFormErrors(formErrors);
                        }
                        setPreviousBlog({ ...previousBlog, url: e.target.value });
                      }}
                      error={formErrors["prevURL"]}
                    />
                  </Col>

                  <Col lg={2}>
                    <Checkbox
                      placeholder="External URL"
                      onChange={(checked: boolean) => setPreviousBlog({ ...previousBlog, openInNewTab: checked })}
                      value={previousBlog.openInNewTab}
                      className="mt-lg-2 pt-4 ms-lg-auto w-fit"
                    />
                  </Col>
                </Row>
              </Form.Group>

              <p className="mt-3 mb-2 text-xs">Next Blog</p>
              <Form.Group className="mb-5">
                <Row>
                  <Col lg={5}>
                    <Input
                      label="Title"
                      focusOnError={true}
                      placeholder="Next blog title"
                      value={nextBlog?.title}
                      onChange={(e) => {
                        if (formErrors["nextTitle"]) {
                          delete formErrors.nextTitle;
                          setFormErrors(formErrors);
                        }
                        setNextBlog({ ...nextBlog, title: e.target.value });
                      }}
                      error={formErrors["nextTitle"]}
                    />
                  </Col>

                  <Col lg={5}>
                    <Input
                      label="URL"
                      focusOnError={true}
                      placeholder="Next blog URL"
                      value={nextBlog?.url}
                      onChange={(e) => {
                        if (formErrors["nextURL"]) {
                          delete formErrors.nextURL;
                          setFormErrors(formErrors);
                        }
                        setNextBlog({ ...nextBlog, url: e.target.value });
                      }}
                      error={formErrors["nextURL"]}
                    />
                  </Col>

                  <Col lg={2}>
                    <Checkbox
                      placeholder="External URL"
                      onChange={(checked: boolean) => setNextBlog({ ...nextBlog, openInNewTab: checked })}
                      value={nextBlog.openInNewTab}
                      className="mt-lg-2 pt-4 ms-lg-auto w-fit"
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mt-3 is-invalid">
                <FileInput
                  selectedFiles={banner ? [banner] : []}
                  accept={{
                    "image/*": [],
                  }}
                  height={180}
                  width={270}
                  placeholder="Upload Banner Image"
                  error={formErrors["banner"]}
                  onDrop={(files) => {
                    if (formErrors["banner"]) {
                      delete formErrors.banner;
                      setFormErrors(formErrors);
                    }
                    if (files?.length) {
                      setBanner(files[0]);
                    } else {
                      setBanner(undefined);
                    }
                  }}
                  containerClass="text-white"
                />
              </Form.Group>

              <Form.Group className="mt-3 mb-4">
                <InputSelect
                  loadingMessage="Loading types"
                  focusOnError={true}
                  onChange={(val: any) => {
                    if (formErrors["type"]) {
                      delete formErrors.type;
                      setFormErrors(formErrors);
                    }
                    if (val?.value) setType(val.value);
                    else setType("");
                  }}
                  labelKey="label"
                  valueKey="value"
                  label="Blog Type"
                  placeholder="Select Type"
                  value={blogTypes.find(
                    (x) => x.value === type
                  )}
                  options={blogTypes}
                  error={formErrors["type"]}
                />
              </Form.Group>


              <Form.Group className="mt-3 mb-4">
                <InputSelect
                  focusOnError={true}
                  onChange={(val: any) => {
                    if (formErrors["site"]) {
                      delete formErrors.site;
                      setFormErrors(formErrors);
                    }
                    if (val?.value) setSite(val.value);
                    else setSite("");
                  }}
                  labelKey="label"
                  valueKey="value"
                  label="Hosting Site"
                  placeholder="Select Site"
                  value={hostingSite.find(
                    (x) => x.value === site
                  )}
                  options={hostingSite}
                  error={formErrors["site"]}
                />
              </Form.Group>

              <div className="ms-5">
                <Form.Group className="mt-3">
                  <Form.Label>Overview</Form.Label>
                  <RichTextEditor
                    value={overview}
                    onChange={(val) => {
                      if (formErrors["overview"]) {
                        delete formErrors.overview;
                        setFormErrors(formErrors);
                      }
                      setOverview(val);
                    }}
                  />
                  {formErrors["overview"] ? (
                    <div className="text-invalid-feedback mt-1">
                      {formErrors["overview"]}
                    </div>
                  ) : undefined}
                </Form.Group>

                {article.map((section, id) => {
                  switch (section.type) {
                    case "Para":
                      return (
                        <Form.Group className="mt-4 border radius-base p-2" key={"section" + id}>
                          <div className="d-flex justify-content-end">
                            {id > 0 ? <FontAwesomeIcon
                              className="cursor-pointer px-1 d-block"
                              onClick={() => arrayMove(id, id - 1)}
                              icon={faAngleUp}
                              color="white"
                            /> : <></>}
                            {id < (article.length - 1) ? <FontAwesomeIcon
                              className="cursor-pointer ms-3 px-1 d-block"
                              onClick={() => arrayMove(id, id + 1)}
                              icon={faAngleDown}
                              color="white"
                            /> : <></>}
                            <FontAwesomeIcon
                              className="cursor-pointer ms-3 px-1 d-block"
                              onClick={() => { article.splice(id, 1); setArticle([...article]); }}
                              icon={faClose}
                              color="red"
                            />
                          </div>
                          <Form.Label>{id + " Para"}</Form.Label>
                          <RichTextEditor
                            value={article[id]?.content}
                            onChange={(val) => {
                              let holder = [...article]
                              holder[id].content = val;
                              setArticle(holder);
                            }}
                          />
                        </Form.Group>
                      )

                    case "Quote":
                      return (
                        <Form.Group className="mt-4 border radius-base p-2" key={"section" + id}>
                          <div className="d-flex justify-content-end">
                            {id > 0 ? <FontAwesomeIcon
                              className="cursor-pointer px-1 d-block"
                              onClick={() => arrayMove(id, id - 1)}
                              icon={faAngleUp}
                              color="white"
                            /> : <></>}
                            {id < (article.length - 1) ? <FontAwesomeIcon
                              className="cursor-pointer ms-3 px-1 d-block"
                              onClick={() => arrayMove(id, id + 1)}
                              icon={faAngleDown}
                              color="white"
                            /> : <></>}
                            <FontAwesomeIcon
                              className="cursor-pointer ms-3 px-1 d-block"
                              onClick={() => { article.splice(id, 1); setArticle([...article]); }}
                              icon={faClose}
                              color="red"
                            />
                          </div>
                          <Row>
                            <Col sm={12} lg={6}>
                              <Input
                                label={id + " Quote"}
                                asTextArea
                                placeholder="Enter quote"
                                value={article[id]?.content}
                                onChange={(e) => {
                                  let holder = [...article]
                                  holder[id].content = e.target.value;
                                  setArticle(holder);
                                }}
                              />
                            </Col>
                            <Col sm={12} lg={6}>
                              <Input
                                label={id + " Author"}
                                placeholder="Author Name"
                                value={article[id]?.author}
                                onChange={(e) => {
                                  let holder = [...article]
                                  holder[id].author = e.target.value;
                                  setArticle(holder);
                                }}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      )

                    case "Columns":
                      return (
                        <Form.Group className="mt-4 border radius-base p-2" key={"section" + id}>
                          <div className="d-flex justify-content-end">
                            {id > 0 ? <FontAwesomeIcon
                              className="cursor-pointer px-1 d-block"
                              onClick={() => arrayMove(id, id - 1)}
                              icon={faAngleUp}
                              color="white"
                            /> : <></>}
                            {id < (article.length - 1) ? <FontAwesomeIcon
                              className="cursor-pointer ms-3 px-1 d-block"
                              onClick={() => arrayMove(id, id + 1)}
                              icon={faAngleDown}
                              color="white"
                            /> : <></>}
                            <FontAwesomeIcon
                              className="cursor-pointer ms-3 px-1 d-block"
                              onClick={() => { article.splice(id, 1); setArticle([...article]); }}
                              icon={faClose}
                              color="red"
                            />
                          </div>
                          <Row>
                            <Col sm={12} lg={6}>
                              <Form.Label>{id + " Column Left"}</Form.Label>
                              <RichTextEditor
                                value={article[id]?.content?.leftBlock}
                                onChange={(val) => {
                                  let holder = [...article]
                                  holder[id].content.leftBlock = val;
                                  setArticle(holder);
                                }}
                              />
                            </Col>
                            <Col sm={12} lg={6}>
                              <Form.Label>{id + " Column Right"}</Form.Label>
                              <RichTextEditor
                                value={article[id]?.content?.rightBlock}
                                onChange={(val) => {
                                  let holder = [...article]
                                  holder[id].content.rightBlock = val;
                                  setArticle(holder);
                                }}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      )

                    case "ImageColumn":
                      return (
                        <Form.Group className="mt-4 border radius-base p-2" key={"section" + id}>
                          <div className="d-flex justify-content-end">
                            {id > 0 ? <FontAwesomeIcon
                              className="cursor-pointer px-1 d-block"
                              onClick={() => arrayMove(id, id - 1)}
                              icon={faAngleUp}
                              color="white"
                            /> : <></>}
                            {id < (article.length - 1) ? <FontAwesomeIcon
                              className="cursor-pointer ms-3 px-1 d-block"
                              onClick={() => arrayMove(id, id + 1)}
                              icon={faAngleDown}
                              color="white"
                            /> : <></>}
                            <FontAwesomeIcon
                              className="cursor-pointer ms-3 px-1 d-block"
                              onClick={() => { article.splice(id, 1); setArticle([...article]); }}
                              icon={faClose}
                              color="red"
                            />
                          </div>

                          <Row>
                            {/* Image Element */}
                            <Col sm={12} lg={6}>
                              <div>
                                {/* <FileInput
                                    // selectedFiles={article[id]?.content ? [article[id].content] : []}
                                    accept={{
                                        "image/*": [],
                                    }}
                                    height={180}
                                    width={270}
                                    placeholder="Upload Section Image"
                                    onDrop={(files) => {
                                      if (files?.length) {
                                          let holder = [...article]
                                          convertToBase64(files[0]).then((obj) => {
                                            holder[id].content.leftBlock = obj;
                                            setArticle(holder);
                                          }).catch(e => console.log(e));
                                      } else {
                                          let holder = [...article]
                                          holder[id].content.leftBlock = undefined;
                                          setArticle(holder);
                                      }
                                    }}
                                    containerClass="text-white"
                                  /> */}

                                <Input
                                  label={id + " Image URL"}
                                  placeholder="Enter image URL"
                                  value={article[id].content.leftBlock}
                                  onChange={(e) => {
                                    let holder = [...article]
                                    holder[id].content.leftBlock = e.target.value;
                                    setArticle(holder);
                                  }}
                                />
                              </div>
                              <div className="mt-3">
                                <Input
                                  label={id + " Image Provider"}
                                  placeholder="Provider Name"
                                  value={article[id]?.author}
                                  onChange={(e) => {
                                    let holder = [...article]
                                    holder[id].author = e.target.value;
                                    setArticle(holder);
                                  }}
                                />
                              </div>
                            </Col>

                            {/* Text Column */}
                            <Col sm={12} lg={6}>
                              <Form.Label>{id + " Column Right"}</Form.Label>
                              <RichTextEditor
                                value={article[id]?.content?.rightBlock}
                                onChange={(val) => {
                                  let holder = [...article]
                                  holder[id].content.rightBlock = val;
                                  setArticle(holder);
                                }}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      )

                    case "ColumnImage":
                      return (
                        <Form.Group className="mt-4 border radius-base p-2" key={"section" + id}>
                          <div className="d-flex justify-content-end">
                            {id > 0 ? <FontAwesomeIcon
                              className="cursor-pointer px-1 d-block"
                              onClick={() => arrayMove(id, id - 1)}
                              icon={faAngleUp}
                              color="white"
                            /> : <></>}
                            {id < (article.length - 1) ? <FontAwesomeIcon
                              className="cursor-pointer ms-3 px-1 d-block"
                              onClick={() => arrayMove(id, id + 1)}
                              icon={faAngleDown}
                              color="white"
                            /> : <></>}
                            <FontAwesomeIcon
                              className="cursor-pointer ms-3 px-1 d-block"
                              onClick={() => { article.splice(id, 1); setArticle([...article]); }}
                              icon={faClose}
                              color="red"
                            />
                          </div>

                          <Row>
                            <Col sm={12} lg={6}>
                              <Form.Label>{id + " Column Right"}</Form.Label>
                              <RichTextEditor
                                value={article[id]?.content?.leftBlock}
                                onChange={(val) => {
                                  let holder = [...article]
                                  holder[id].content.leftBlock = val;
                                  setArticle(holder);
                                }}
                              />
                            </Col>

                            <Col sm={12} lg={6}>
                              <div>
                                <div>
                                  {/* <FileInput
                                      // selectedFiles={article[id]?.content ? [article[id].content] : []}
                                      accept={{
                                          "image/*": [],
                                      }}
                                      height={180}
                                      width={270}
                                      placeholder="Upload Section Image"
                                      onDrop={(files) => {
                                        if (files?.length) {
                                            let holder = [...article]
                                            convertToBase64(files[0]).then((obj) => {
                                              holder[id].content.rightBlock = obj;
                                              setArticle(holder);
                                            }).catch(e => console.log(e));
                                        } else {
                                            let holder = [...article]
                                            holder[id].content.rightBlock = undefined;
                                            setArticle(holder);
                                        }
                                      }}
                                      containerClass="text-white"
                                    /> */}

                                  <Input
                                    label={id + " Image URL"}
                                    placeholder="Enter image URL"
                                    value={article[id].content.rightBlock}
                                    onChange={(e) => {
                                      let holder = [...article]
                                      holder[id].content.rightBlock = e.target.value;
                                      setArticle(holder);
                                    }}
                                  />
                                </div>
                                <div className="mt-3">
                                  <Input
                                    label={id + " Image Provider"}
                                    placeholder="Provider Name"
                                    value={article[id]?.author}
                                    onChange={(e) => {
                                      let holder = [...article]
                                      holder[id].author = e.target.value;
                                      setArticle(holder);
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Form.Group>
                      )

                    case "Image":
                      return (
                        <Form.Group className="mt-4 border radius-base p-2" key={"section" + id}>
                          <div className="d-flex justify-content-end">
                            {id > 0 ? <FontAwesomeIcon
                              className="cursor-pointer px-1 d-block"
                              onClick={() => arrayMove(id, id - 1)}
                              icon={faAngleUp}
                              color="white"
                            /> : <></>}
                            {id < (article.length - 1) ? <FontAwesomeIcon
                              className="cursor-pointer ms-3 px-1 d-block"
                              onClick={() => arrayMove(id, id + 1)}
                              icon={faAngleDown}
                              color="white"
                            /> : <></>}
                            <FontAwesomeIcon
                              className="cursor-pointer ms-3 px-1 d-block"
                              onClick={() => { article.splice(id, 1); setArticle([...article]); }}
                              icon={faClose}
                              color="red"
                            />
                          </div>
                          <Row>
                            <Col sm={6}>
                              {/* <FileInput
                                  // selectedFiles={article[id]?.content ? [article[id].content] : []}
                                  accept={{
                                      "image/*": [],
                                  }}
                                  height={180}
                                  width={270}
                                  placeholder="Upload Section Image"
                                  onDrop={(files) => {
                                      if (files?.length) {
                                          let holder = [...article];
                                          // holder[id].content = files[0];
                                          // setArticle(holder);
                                          convertToBase64(files[0]).then((obj) => {
                                            holder[id].content = obj;
                                            setArticle(holder);
                                          }).catch(e => console.log(e));
                                      } else {
                                          let holder = [...article]
                                          holder[id].content = undefined;
                                          setArticle(holder);
                                      }
                                  }}
                                  containerClass="text-white"
                                /> */}

                              <Input
                                label={id + " Image"}
                                placeholder="Enter image URL"
                                value={article[id].content}
                                onChange={(e) => {
                                  let holder = [...article]
                                  holder[id].content = e.target.value;
                                  setArticle(holder);
                                }}
                              />
                            </Col>
                            <Col sm={12} lg={6}>
                              <Input
                                label={id + " Image Provider"}
                                placeholder="Provider Name"
                                value={article[id]?.author}
                                onChange={(e) => {
                                  let holder = [...article]
                                  holder[id].author = e.target.value;
                                  setArticle(holder);
                                }}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      )

                    default:
                      return <></>
                  }
                })}

              </div>

              <Form.Group className="mt-4">
                <Row>
                  <Col sm={6} lg={4} className="p-2">
                    <Button className="w-100 rounded-pill" onClick={addParaElement} variant="primary">
                      <FontAwesomeIcon
                        icon={faAdd}
                        color="me-2 text-color-secondary"
                      />
                      <span>Add Paragraph</span>
                    </Button>
                  </Col>

                  <Col sm={6} lg={4} className="p-2">
                    <Button className="w-100 rounded-pill" onClick={addColumnsElement} variant="secondary">
                      <FontAwesomeIcon
                        icon={faAdd}
                        color="me-2 text-color-secondary"
                      />
                      <span>Add Columns</span>
                    </Button>
                  </Col>

                  <Col sm={6} lg={4} className="p-2">
                    <Button className="w-100 rounded-pill" onClick={addQuoteElement} variant="primary">
                      <FontAwesomeIcon
                        icon={faAdd}
                        color="me-2 text-color-secondary"
                      />
                      <span>Add Quote</span>
                    </Button>
                  </Col>

                  <Col sm={6} lg={4} className="p-2">
                    <Button className="w-100 rounded-pill" onClick={addImageElement} variant="secondary">
                      <FontAwesomeIcon
                        icon={faAdd}
                        color="me-2 text-color-secondary"
                      />
                      <span>Add Image</span>
                    </Button>
                  </Col>

                  <Col sm={6} lg={4} className="p-2">
                    <Button className="w-100 rounded-pill" onClick={addColumnImageElement} variant="primary">
                      <FontAwesomeIcon
                        icon={faAdd}
                        color="me-2 text-color-secondary"
                      />
                      <span>Add Column | Image</span>
                    </Button>
                  </Col>

                  <Col sm={6} lg={4} className="p-2">
                    <Button className="w-100 rounded-pill" onClick={addImageColumnElement} variant="secondary">
                      <FontAwesomeIcon
                        icon={faAdd}
                        color="me-2 text-color-secondary"
                      />
                      <span>Add Image | Column</span>
                    </Button>
                  </Col>
                </Row>
              </Form.Group>

              {formErrors[""] ? (
                <div className="text-invalid-feedback m-2">
                  {formErrors[""]}
                </div>
              ) : undefined}

              <Form.Group
                className="mt-5 d-flex gap-3"
                style={{
                  textAlign: "right",
                }}
              >
                <Button type="button" variant="secondary" className="rounded-pill" onClick={createBlogObject}>Preview</Button>
                <Button type="reset" variant="secondary" className="rounded-pill">Reset</Button>
                <Button type="submit" className="rounded-pill">Update</Button>
              </Form.Group>
            </Form>
          </div>
        </>
      ) : undefined}

      <LoadingSpinner show={loading} text={"Updating Blog"} />

      <Modal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        centered
        dialogClassName={`${post && post?.site === "Oblivious" ? "oblv-login-modal" : "login-modal"} xs-box`}
      >
        <Modal.Header className="" closeButton><p></p></Modal.Header>
        <Modal.Body className="mx-auto">
          {post && post?.site === "Oblivious" ? <ReadObliviousBlogPage data={post} /> : <ReadBlogPage data={post} />}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default UpdateBlogPage;
