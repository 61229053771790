import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BlogApi from "../../api/blog-api";
import { IBlogData, ICreateBlogPayload, IUpdateBlogPayload, IFetchBlogListPayload, IBlogFilterData, IBlog } from "../../types/blog-types";

interface IBlogState {
    pageData: IBlogData;
    filterData: IBlogFilterData;
    fetching: boolean;

    blogInfo: IBlog | null;
}

const initialState: IBlogState = {
    pageData: {
        data: [],
    },
    filterData: {},
    fetching: false,

    blogInfo: null,
};

/*thunks*/
const createBlog = createAsyncThunk('blog/create', async (payload: ICreateBlogPayload, thunkAPI) => {
    try {
        const response = await BlogApi.create(payload);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const loadBlogs = createAsyncThunk('blog/getAll', async (payload: IFetchBlogListPayload, thunkAPI) => {
    try {
        const response = await BlogApi.getAll(payload);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const updateBlog = createAsyncThunk('blog/update', async (payload: IUpdateBlogPayload, thunkAPI) => {
    try {
        const response = await BlogApi.update(payload);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const deleteBlog = createAsyncThunk(
    "blog/delete",
    async (id: string, thunkAPI) => {
        try {
            await BlogApi.delete(id);
            return id;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const loadBlogInfoById = createAsyncThunk(
    'blog/get/:id',
    async ({ _id }: { _id: string }, thunkAPI) => {
        try {
            const response = await BlogApi.get(_id);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
)

export const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        setFetching: (s, a) => {
            s.fetching = a.payload;
        },
        setFilterData: (s, a) => {
            s.filterData = a.payload;
        },

        setBlogPageData: (s, a) => {
            s.pageData = a.payload;
        },
        setBlogInfo: (s, a) => {
            s.blogInfo = a.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(createBlog.fulfilled, (s, a) => {
            s.pageData.data?.push(a.payload);
        })
        builder.addCase(loadBlogs.pending, (s, a) => {
            s.fetching = true;
        })
        builder.addCase(loadBlogs.fulfilled, (s, a) => {
            s.pageData = a.payload;
            s.fetching = false;
        })
        builder.addCase(loadBlogs.rejected, (s, a) => {
            s.fetching = false;
        })
        builder.addCase(updateBlog.fulfilled, (s, a) => {
            let index = s.pageData.data?.findIndex(x => x._id === a.payload._id);
            if (index > -1) {
                const ds = s.pageData?.data[index];
                s.pageData.data[index] = { ...ds, ...a.payload };
            }
        })
        builder.addCase(deleteBlog.fulfilled, (s, a) => {
            let index = s.pageData.data?.findIndex((x) => x._id === a.payload);
            if (index > -1) {
                s.pageData.data.splice(index, 1);
            }
        });

        builder.addCase(loadBlogInfoById.fulfilled, (s, a) => {
            s.blogInfo = a.payload
        });
    }
})

export {
    createBlog,
    loadBlogs,
    updateBlog,
    deleteBlog,

    loadBlogInfoById,
};
export const {
    setFetching,

    setBlogPageData,
    setBlogInfo,
} = blogSlice.actions;
export default blogSlice.reducer;
