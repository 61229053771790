import { convertDate } from "../../utils/helper-methods";
import { useEffect, useState } from "react";
import { IBlog } from "../../types/blog-types";
import { SectionType } from "../../enums/blog-section";
import "./index.scss";
import { BlogBanner } from '../../configs/api-config';

type BlogProps = {
  data: IBlog;
};

function ReadBlogPage(props: BlogProps) {
  const [post, setPost] = useState<IBlog>();

  useEffect(() => {
    if(props.data){
      setPost(props.data);
    }
  }, [props.data]);

  const renderImageSection = (section : any) => {
    return section.content ? (
      <div className='oblv-post-section my-4' key={"img" + section.id}>
        <img src={section.content.result || section.content} alt='' className="oblv-section-image"/>
      </div>
    ) : <></>;
  }

  const renderParaSection = (section : any) => {
    return (
      <div key={"para" + section.id}>
        {section?.content ? 
          <div className="oblv-post-section mb-4 text-base"
            dangerouslySetInnerHTML={{ __html: section.content }}
          ></div> : <></>
        }
      </div>
    );
  }

  return (
    <div className="page-container oblv-blog-page">

      {post ? 
        <>
          <div className="d-flex flex-column mb-21">

            <div className="font-medium mb-2 d-flex">
              <span>{convertDate(post.createdAt.toUTCString())}</span>
              <ul className="mb-0"><li className="">{post.readTime}</li></ul>
            </div>

            <p className="text-heading-oblv">
              {post.title}
            </p>
            <p className="text-subheading-oblv">
              {post.subTitle}
            </p>
            
            <img
              className="oblv-blog-img"
              src={BlogBanner(post._id)} 
              width={885} 
              height={380}
              alt={post.title}
            />
          </div>

          <div>
            {
              post.article.map((section : any) => {
                switch (section.type) {
                  case SectionType.Image:
                    return renderImageSection(section);
                  case SectionType.Para:
                    return renderParaSection(section);
                  default:
                    return undefined;
                }
              })
            }
          </div>
        </> : undefined
      }
    </div>
  )
}

export default ReadBlogPage;