import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

// import: assets
import upcomingIcon from "../../assets/icons/upcoming.png";
import ongoingIcon from "../../assets/icons/ongoing.png";
import finalisedIcon from "../../assets/icons/finalised.png";
import sandboxIcon from "../../assets/icons/sandbox.png";

// import: styles
import "./index.scss";

// import: constants
import { COMPETITIONS, CREATE } from "../../router/routes";

// import: enums
import { UserRole } from "../../enums/roles";
import { PrizeType as PrizeTypeEnum } from "../../enums/prize-type";

// import: types
import { ICompetition } from "../../types/competition-types";

// import: utils
import { competitionStatus } from "../../utils/helper-methods";
// import { addDays } from "../../utils/helper-methods";

// import: data
// import: store
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import {
    onChangeSearchText,
    setSearchPlaceholder,
} from "../../store/slices/shared-states-slice";
import { loadCompetitions } from "../../store/slices/competition-slice";

// import: api
// import: config
// import: components
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import LoadingSpinner from "../../components/loading-spinner";
import CompetitionListItem from "../../components/list-items/competition-list-item";
import Button from "react-bootstrap/Button";

export default function CompetitionPage() {
    const dispatch = useAppDispatch();
    const pageSize = parseInt(
        process.env.React_APP_COMPETITIONS_DATA_LENGTH || "100"
    );
    const user = useAppSelector((s) => s.auth.user);
    const competitions = useAppSelector((s) => s.competition.pageData.data);
    const fetchingCompetitions = useAppSelector((s) => s.competition.fetching);

    const [selectedTab, setSelectedTab] = useState<string>("tabAll");
    const [upcoming, setUpcoming] = useState<ICompetition[]>([]);
    const [ongoing, setOngoing] = useState<ICompetition[]>([]);
    const [finalized, setFinalized] = useState<ICompetition[]>([]);
    const [sandbox, setSandbox] = useState<ICompetition[]>([]);

    const searchText = useAppSelector((s) => s.sharedStates.navbarSearchText);
    const navigate = useNavigate();

    // const updateSelectedTab = () => {
    //     if (competitions.length !== 0) {
    //         const upcomingComp = competitions.filter((x) => competitionStatus(x.startDate, x.endDate) === "upcoming" ? true : false);
    //         const ongoingComp = competitions.filter((x) => competitionStatus(x.startDate, x.endDate) === "ongoing" ? true : false);
    //         const finalizedComp = competitions.filter((x) => competitionStatus(x.startDate, x.endDate) === "completed" ? true : false);

    //         if (upcomingComp.length !== 0 && upcomingComp[0].startDate < addDays(new Date(), 2)){
    //             setSelectedTab("tabUpcoming");
    //         }
    //         else if (ongoingComp.length !== 0 && ongoingComp[0].startDate > addDays(new Date(), -2)){
    //             setSelectedTab("tabOngoing");
    //         }
    //         else if (finalizedComp.length !== 0 && finalizedComp[0].endDate > addDays(new Date(), -2)){
    //             setSelectedTab("tabCompleted");
    //         }
    //         else setSelectedTab("tabAll");
    //     }
    // }

    useEffect(() => {
        if (competitions && competitions.length) {
            setUpcoming(competitions.filter((x) => competitionStatus(x.startDate, x.endDate) === "upcoming" ? true : false));
            setOngoing(competitions.filter((x) => competitionStatus(x.startDate, x.endDate) === "ongoing" ? true : false));
            setFinalized(competitions.filter((x) => competitionStatus(x.startDate, x.endDate) === "completed" ? true : false));
            setSandbox(competitions.filter((x) => x.prizeDetail?.prizeType === PrizeTypeEnum.Knowledge ? true : false));
            // updateSelectedTab();
        }
    }, [competitions]);

    useEffect(() => {
        if (!competitions?.length) {
            dispatch(loadCompetitions({ page: 1, size: pageSize }))
        }

        dispatch(onChangeSearchText(""));
        dispatch(setSearchPlaceholder("Search Competitions"));
        return () => {
            dispatch(setSearchPlaceholder(""));
        };
    }, [dispatch]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searchText) {
                if (searchText.length >= 3) {
                    dispatch(loadCompetitions({ page: 1, size: pageSize, title: searchText }));
                }
                else {
                    dispatch(loadCompetitions({ page: 1, size: pageSize }));
                }
            }
        }, 1000)

        return () => clearTimeout(timer);
    }, [searchText]);

    return (
        <div className="page-container lg-box">
            <div>
                <div className="page-heading-container">
                    <span className="text-3xl mb-21" id="competitionPage">
                        Competitions
                    </span>
                    <div className="text-xs">
                        <p>
                            Welcome to Antigranular Data Science Competitions!
                            We're excited to have you on board. If you're new
                            here, be sure to check out our{" "}
                            <a
                                className="link-ag-1"
                                href="https://docs.antigranular.com/category/quickstart-guides"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Quickstart guide
                            </a>{" "}
                            to submit your first entry quickly.
                        </p>
                        <p className="mb-0">
                            Join our community to level up your skills in
                            model-building and privacy protection. So why not
                            try out one of our data science hackathons today?
                            You can climb the leaderboard while building the
                            cutting edge of privacy-preserving data science. We
                            can't wait to see what you'll accomplish!
                        </p>
                    </div>
                </div>
                {user?.roles?.includes(UserRole.Admin) ? (
                    <Button
                        className="mb-3 rounded-pill"
                        onClick={() => navigate(`/${COMPETITIONS}/${CREATE}`)}
                    >
                        Create
                    </Button>
                ) : undefined}
            </div>

            <Tabs
                className="pb-1"
                activeKey={selectedTab}
                onSelect={(k) => setSelectedTab(k || selectedTab)}
            >
                <Tab title={"All"} eventKey={"tabAll"}>
                    {ongoing?.length > 0 ? (
                        <div className="devide-list-section">
                            <div className="mb-1 d-flex align-items-center justify-content-between">
                                <span className="text-lg text-thick d-flex align-items-center">
                                    <img
                                        className="me-2"
                                        alt=""
                                        src={ongoingIcon}
                                        width={24}
                                        height={24}
                                    />
                                    Ongoing
                                </span>
                                <span
                                    className="text-xs link pre-text"
                                    onClick={() => {
                                        setSelectedTab("tabOngoing");
                                        document
                                            .getElementById("competitionPage")
                                            ?.scrollIntoView({
                                                behavior: "smooth",
                                            });
                                    }}
                                >
                                    See all &nbsp;&nbsp;{`>`}
                                </span>
                            </div>
                            <div className="listing-table-container">
                                {ongoing.slice(0, 12).map((comp, idx) => (
                                    <CompetitionListItem
                                        to={`/${COMPETITIONS}/` + comp._id}
                                        item={comp}
                                        className="slide_in_appear"
                                        key={
                                            "Competition_Completed" +
                                            comp._id +
                                            idx
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                    {sandbox?.length > 0 ? (
                        <div className="devide-list-section">
                            <div className="mb-1 d-flex align-items-center justify-content-between">
                                <span className="text-lg text-thick d-flex align-items-center">
                                    <img
                                        className="me-2"
                                        alt=""
                                        src={sandboxIcon}
                                        width={24}
                                        height={24}
                                    />
                                    Sandbox
                                </span>
                                <span
                                    className="text-xs link"
                                    onClick={() => {
                                        setSelectedTab("tabSandbox");
                                        document
                                            .getElementById("competitionPage")
                                            ?.scrollIntoView({
                                                behavior: "smooth",
                                            });
                                    }}
                                >
                                    See all &nbsp;&nbsp;{`>`}
                                </span>
                            </div>
                            <div className="listing-table-container">
                                {sandbox.slice(0, 4).map((comp, idx) => (
                                    <CompetitionListItem
                                        to={`/${COMPETITIONS}/` + comp._id}
                                        item={comp}
                                        className="slide_in_appear"
                                        key={
                                            "Competition_Completed" +
                                            comp._id +
                                            idx
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                    {upcoming?.length > 0 ? (
                        <div className="devide-list-section">
                            <div className="mb-1 d-flex align-items-center justify-content-between">
                                <span className="text-lg text-thick d-flex align-items-center">
                                    <img
                                        className="me-2"
                                        alt=""
                                        src={upcomingIcon}
                                        width={24}
                                        height={24}
                                    />
                                    Upcoming
                                </span>
                                <span
                                    className="text-xs link"
                                    onClick={() => {
                                        setSelectedTab("tabUpcoming");
                                        document
                                            .getElementById("competitionPage")
                                            ?.scrollIntoView({
                                                behavior: "smooth",
                                            });
                                    }}
                                >
                                    See all &nbsp;&nbsp;{`>`}
                                </span>
                            </div>
                            <div className="listing-table-container">
                                {upcoming.slice(0, 4).map((comp, idx) => (
                                    <CompetitionListItem
                                        to={`/${COMPETITIONS}/` + comp._id}
                                        item={comp}
                                        className="slide_in_appear"
                                        key={
                                            "Competition_Completed" +
                                            comp._id +
                                            idx
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                    {finalized?.length > 0 ? (
                        <div className="devide-list-section">
                            <div className="mb-1 d-flex align-items-center justify-content-between">
                                <span className="text-lg text-thick d-flex align-items-center">
                                    <img
                                        className="me-2"
                                        alt=""
                                        src={finalisedIcon}
                                        width={24}
                                        height={24}
                                    />
                                    Finalised
                                </span>
                                <span
                                    className="text-xs link"
                                    onClick={() => {
                                        setSelectedTab("tabCompleted");
                                        document
                                            .getElementById("competitionPage")
                                            ?.scrollIntoView({
                                                behavior: "smooth",
                                            });
                                    }}
                                >
                                    See all &nbsp;&nbsp;{`>`}
                                </span>
                            </div>
                            <div className="listing-table-container">
                                {finalized.slice(0, 4).map((comp, idx) => (
                                    <CompetitionListItem
                                        to={`/${COMPETITIONS}/` + comp._id}
                                        item={comp}
                                        className="slide_in_appear"
                                        key={"Competition_Completed" + comp._id}
                                    />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </Tab>

                <Tab title={"Ongoing"} eventKey={"tabOngoing"}>
                    <div className="listing-table-container">
                        {ongoing ? (
                            ongoing.map((comp, idx) => (
                                <CompetitionListItem
                                    to={`/${COMPETITIONS}/` + comp._id}
                                    item={comp}
                                    key={
                                        "Competition_Completed" + comp._id + idx
                                    }
                                />
                            ))
                        ) : (
                            <></>
                        )}

                        {competitions && ongoing.length === 0 ? (
                            <p className="py-5 text-center text-color-tertiary w-100 my-0">
                                No Ongoing competitions
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>
                </Tab>

                <Tab title={"Sandbox"} eventKey={"tabSandbox"}>
                    <div className="listing-table-container">
                        {sandbox ? (
                            sandbox.map((comp, idx) => (
                                <CompetitionListItem
                                    to={`/${COMPETITIONS}/` + comp._id}
                                    item={comp}
                                    key={
                                        "Competition_Completed" + comp._id + idx
                                    }
                                />
                            ))
                        ) : (
                            <></>
                        )}

                        {competitions && sandbox.length === 0 ? (
                            <p className="py-5 text-center text-color-tertiary w-100 my-0">
                                No Sandbox competitions
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>
                </Tab>

                <Tab title={"Upcoming"} eventKey={"tabUpcoming"}>
                    <div className="listing-table-container">
                        {upcoming ? (
                            upcoming.map((comp, idx) => (
                                <CompetitionListItem
                                    to={`/${COMPETITIONS}/` + comp._id}
                                    item={comp}
                                    key={
                                        "Competition_Completed" + comp._id + idx
                                    }
                                />
                            ))
                        ) : (
                            <></>
                        )}

                        {competitions && upcoming.length === 0 ? (
                            <p className="py-5 text-center text-color-tertiary w-100 my-0">
                                No Upcoming competitions
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>
                </Tab>

                <Tab title={"Finalised"} eventKey={"tabCompleted"}>
                    <div className="listing-table-container">
                        {finalized ? (
                            finalized.map((comp, idx) => (
                                <CompetitionListItem
                                    to={`/${COMPETITIONS}/` + comp._id}
                                    item={comp}
                                    key={"Competition_Completed" + comp._id}
                                />
                            ))
                        ) : (
                            <></>
                        )}

                        {competitions && finalized.length === 0 ? (
                            <div className="py-5 text-center text-color-tertiary w-100 my-0">
                                No completed competitions
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </Tab>
            </Tabs>

            <LoadingSpinner
                show={fetchingCompetitions}
                text={"Getting competitions"}
            />
        </div>
    );
}
