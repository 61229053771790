import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

// import: assets
import logo from "../../assets/logo.svg";
import logOutIcon from "../../assets/icons/log-out.png";
import userCircle from "../../assets/icons/user-circle.png";
import settings from "../../assets/icons/settings.png";
// import resources from "../../assets/icons/resources.svg";
import discord from "../../assets/icons/discord.svg";
import discordSelected from "../../assets/icons/discord-selected.svg";
// import selectedResources from "../../assets/icons/resource-selected.png";

// import: styles
// import: constants
import { DISCORD, PROFILE, ROOT, SETTINGS } from "../../router/routes";

// import: enums
// import: types
// import: utils
// import: data
import { SidebarMenus } from "../../router/sidebar-menu";

// import: store
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import {
    logout,
    setRegistrationModalVisible,
    showLoginModal,
} from "../../store/slices/auth-slice";

// import: api
import ProfileApi from "../../api/profile-api";

// import: config
import { REF_CODE_URL_PARAM } from "../../configs/constants";

// import: components
import { Button, Dropdown } from "react-bootstrap";
import UserImg from "../../components/user-img";
import ShareButton from "../../components/share-button";

type IPHeader = {
    minNav?: boolean;
};

export default function Header(props: IPHeader) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = useAppSelector((s) => s.auth.user);
    const [finding, setFinding] = useState<boolean>(true);
    const [shareUrl, setShareUrl] = useState<URL>();
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const mailDescription = `Hey there!

Have you heard about Antigranular?

It's an incredible data science community that enables you to work with privacy-enhancing technologies, learn from other data scientists, enhance your skills, and participate in exciting hackathons.
Use this link to create a profile and join me to explore the world of Eyes Off Data Science!
`;

    const observer = new IntersectionObserver((entries) => {
        const hiddenLinks = document.querySelector<HTMLDivElement>(".joinLink");
        const homeHiddenLinks =
            document.querySelector<HTMLDivElement>(".homeLink");
        if (hiddenLinks) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    hiddenLinks.style.width = "0px";
                    hiddenLinks.style.padding = "0px";
                    hiddenLinks.style.pointerEvents = "none";
                } else {
                    hiddenLinks.style.width =
                        hiddenLinks.scrollWidth + 32 + "px";
                    hiddenLinks.style.padding = "0px 16px";
                    hiddenLinks.style.marginLeft = "0px";
                    hiddenLinks.style.pointerEvents = "all";
                }
            });
        }
        if (homeHiddenLinks) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    homeHiddenLinks.style.width = "0px";
                    homeHiddenLinks.style.padding = "0px";
                    homeHiddenLinks.style.pointerEvents = "none";
                } else {
                    homeHiddenLinks.style.width =
                        homeHiddenLinks.scrollWidth + 32 + "px";
                    homeHiddenLinks.style.padding = "0px 16px";
                    homeHiddenLinks.style.marginLeft = "0px";
                    homeHiddenLinks.style.pointerEvents = "all";
                }
            });
        }
    });

    useEffect(() => {
        if (finding) {
            const logo = document.getElementById("AntigranularLogo");
            if (logo) {
                setFinding(false);
                observer.observe(logo);
            }
        }
    }, [finding]);

    useEffect(() => {
        if (user && process.env.REACT_APP_URL) {
            var url = new URL(process.env.REACT_APP_URL);
            ProfileApi.getReferralCode()
                .then((refCode) => {
                    url.searchParams.append(REF_CODE_URL_PARAM, refCode);
                    setShareUrl(url);
                })
                .catch((err) => {});
        }
    }, [user]);

    return (
        <div className="landing-header position-relative" id="Antigranular">
            {/* {props.backBtn &&
                <Button variant="dark" className="rounded-pill ms-2" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
            } */}
            <div className="d-flex align-items-center">
                <div
                    className="d-lg-none burger-container"
                    onClick={() => setOpenMenu(!openMenu)}
                >
                    <div
                        className={`br-1 bg-octonary ${
                            openMenu && "rotate-br-1"
                        }`}
                    ></div>
                    <div
                        className={`br-2 bg-octonary ${
                            openMenu && "rotate-br-2"
                        }`}
                    ></div>
                </div>
                <Link to={ROOT} id="Logo">
                    <img
                        src={logo}
                        alt="Antigranular"
                        className="logo cursor-pointer"
                        id="AntigranularLogo"
                    />
                </Link>

                <div
                    className={`glazed-card-cover hidden-options-container d-lg-none ${
                        !openMenu && "hide-options"
                    }`}
                >
                    <div className="glazed-card hidden-options-menu ps-md-2">
                        {SidebarMenus?.map((menu, i) =>
                            menu.external ? (
                                <a
                                    key={"hiddenLinks" + i}
                                    href={menu.path}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={`text-color-tertiary px-3 dropdown-links link`}
                                >
                                    <img
                                        src={menu.iconImg}
                                        alt=""
                                        className=" me-21 defaultImage"
                                    />
                                    <img
                                        src={menu.activeIconImg}
                                        alt=""
                                        className=" me-21 selectedImage"
                                    />

                                    <span className="text-base">
                                        {menu.text}
                                    </span>
                                </a>
                            ) : menu.path === "#" ? (
                                <div
                                    key={"hiddenLinks" + i}
                                    onClick={() => {
                                        setIsDropdownOpen(!isDropdownOpen);
                                    }}
                                    className={`px-3 dropdown-links text-color-tertiary link`}
                                >
                                    {menu.dropdownItems ? (
                                        <div className="d-flex flex-column mobile-menu">
                                            <div>
                                                <img
                                                    src={menu.iconImg}
                                                    alt=""
                                                    className=" me-21 defaultImage"
                                                />
                                                <img
                                                    src={menu.activeIconImg}
                                                    alt=""
                                                    className=" me-21 selectedImage"
                                                />

                                                <span className="text-base">
                                                    {menu.text}
                                                </span>
                                                {menu.text === "Community" && (
                                                    <>
                                                        <img
                                                            src={
                                                                menu.dropdownIconImg
                                                            }
                                                            alt=""
                                                            width={20}
                                                            height={20}
                                                            className=" me-21 defaultImage"
                                                            style={{
                                                                filter: "invert(1)",
                                                            }}
                                                        />
                                                        <img
                                                            src={
                                                                menu.activeDropDownIconImg
                                                            }
                                                            alt=""
                                                            width={20}
                                                            height={20}
                                                            className=" me-21 selectedImage"
                                                        />
                                                    </>
                                                )}
                                            </div>

                                            {isDropdownOpen && (
                                                <div className="d-flex flex-column m-auto mt-2">
                                                    {menu.dropdownItems?.map(
                                                        (item, i) => (
                                                            <Link
                                                                to={item.path}
                                                                className="d-flex"
                                                                key={i}
                                                            >
                                                                <h6 className="title">
                                                                    {
                                                                        item
                                                                            .text
                                                                            .title
                                                                    }
                                                                </h6>
                                                            </Link>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            ) : (
                                <Link
                                    key={"hiddenLinks" + i}
                                    to={menu.path}
                                    className={`px-3 dropdown-links ${
                                        menu.path === "/"
                                            ? "link text-color-secondary"
                                            : "text-color-tertiary link"
                                    }`}
                                >
                                    <img
                                        src={menu.iconImg}
                                        alt=""
                                        className=" me-21 defaultImage"
                                    />
                                    <img
                                        src={menu.activeIconImg}
                                        alt=""
                                        className=" me-21 selectedImage"
                                    />

                                    <span className="text-base">
                                        {menu.text}
                                    </span>
                                </Link>
                            )
                        )}

                        <a
                            key={"hiddenLinks45"}
                            href={DISCORD}
                            target="_blank"
                            rel="noreferrer"
                            className={`text-color-tertiary px-3 dropdown-links link`}
                        >
                            <img
                                src={discord}
                                alt=""
                                className=" me-21 defaultImage"
                            />
                            <img
                                src={discordSelected}
                                alt=""
                                className=" me-21 selectedImage"
                            />
                            <span className="text-base">Discord</span>
                        </a>

                        {user && shareUrl ? (
                            <ShareButton
                                className="ms-3 mt-1"
                                url={shareUrl?.href}
                                modalHead="Spread the word and earn rewards"
                                modalBody="Share your unique referral link to invite friends to the platform:"
                                subject={`Invitation to the Eyes Off Data Science from ${user?.fullName}`}
                                description={mailDescription}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
            <div className="order-3">
                {user ? (
                    <Dropdown className="">
                        <Dropdown.Toggle
                            as={"span"}
                            className="navbar-user-dropdown-toggle"
                        >
                            <div className="no-select d-none d-sm-block text-lg text-thick me-3">
                                {user?.fullName ?? ""}
                            </div>
                            <UserImg
                                width={32}
                                height={32}
                                userId={user._id}
                                className="rounded-circle me-2 me-sm-0"
                            />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={"div"}
                            className="p-0 navbar-user-menu-dropdown"
                        >
                            <div className="glazed-card d-flex flex-column p-3 radius-base">
                                <Dropdown.Item
                                    as="div"
                                    className="navbar-user-menu-dropdown-item text-sm text-bold mb-2"
                                    onClick={() => navigate(`/${PROFILE}`)}
                                >
                                    <img
                                        alt=""
                                        className="me-2"
                                        width={24}
                                        height={24}
                                        src={userCircle}
                                    />
                                    Your Profile
                                </Dropdown.Item>
                                <Dropdown.Item
                                    as="div"
                                    className="navbar-user-menu-dropdown-item text-sm text-bold mb-2"
                                    onClick={() => navigate(`/${SETTINGS}`)}
                                >
                                    <img
                                        alt=""
                                        className="me-2"
                                        width={24}
                                        height={24}
                                        src={settings}
                                    />
                                    Settings
                                </Dropdown.Item>
                                <Dropdown.Item
                                    as="div"
                                    className="navbar-user-menu-dropdown-item text-sm text-bold"
                                    onClick={() => {
                                        navigate(ROOT);
                                        dispatch(logout());
                                    }}
                                >
                                    <img
                                        alt=""
                                        className="me-2"
                                        width={24}
                                        height={24}
                                        src={logOutIcon}
                                    />
                                    Sign Out
                                </Dropdown.Item>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                ) : (
                    <div className="order-3 navbar-buttons">
                        <Button
                            type="button"
                            size="sm"
                            variant="primary"
                            onClick={() =>
                                dispatch(setRegistrationModalVisible(true))
                            }
                            className="rounded-pill me-2 me-sm-4"
                        >
                            Sign up
                        </Button>

                        <Button
                            type="button"
                            size="sm"
                            variant="secondary"
                            onClick={() => dispatch(showLoginModal())}
                            className="rounded-pill d-none d-sm-block"
                        >
                            Log in
                        </Button>
                    </div>
                )}
            </div>

            <div
                className={`navbar-menu-container d-none ${
                    !props.minNav && "d-lg-block"
                }`}
            >
                <div className="navbar-menu glass-card">
                    <Link to={ROOT} className={`link selected-link homeLink`}>
                        Home
                    </Link>

                    {SidebarMenus?.map((menu, i) =>
                        menu.external ? (
                            <a
                                key={"navLinks10"}
                                href={menu.path}
                                target="_blank"
                                rel="noreferrer"
                                className="px-3 link"
                            >
                                <span className="text-color-primary">
                                    {menu.text}
                                </span>
                            </a>
                        ) : menu.text !== "Home" ? (
                            menu.path === "#" ? (
                                <div
                                    key={"navLinks" + i}
                                    onClick={() =>
                                        menu.path !== "#" && navigate(menu.path)
                                    }
                                    className={`relative link ps-3 `}
                                >
                                    <span className="pe-1">{menu.text}</span>
                                    <img
                                        src={menu.dropdownIconImg}
                                        alt=""
                                        width={20}
                                        height={20}
                                        className=" me-21 defaultImage"
                                        style={{ filter: "invert(1)" }}
                                    />
                                    <img
                                        src={menu.activeDropDownIconImg}
                                        alt=""
                                        width={20}
                                        height={20}
                                        className=" me-21 selectedImage"
                                    />

                                    {menu.dropdownItems && (
                                        <div className="pt-4 position-absolute">
                                            <div className="navbar-dropdown rounded-3 ">
                                                {menu.dropdownItems?.map(
                                                    (item, i) => (
                                                        <Link
                                                            to={item.path}
                                                            className="items"
                                                            key={i}
                                                        >
                                                            <div className="img-div">
                                                                <img
                                                                    src={
                                                                        item.iconImg
                                                                    }
                                                                    className="img"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="">
                                                                <h5 className="title">
                                                                    {
                                                                        item
                                                                            .text
                                                                            .title
                                                                    }
                                                                </h5>
                                                                <p className="content mb-0">
                                                                    {
                                                                        item
                                                                            .text
                                                                            .content
                                                                    }
                                                                </p>
                                                            </div>
                                                        </Link>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <Link
                                    key={"navLinks10"}
                                    to={menu.path}
                                    className="px-3 link"
                                >
                                    <span className="text-color-primary">
                                        {menu.text}
                                    </span>
                                </Link>
                            )
                        ) : (
                            <></>
                        )
                    )}
                    <div
                        className={`link joinLink ${user ? "d-none" : ""}`}
                        onClick={() =>
                            dispatch(setRegistrationModalVisible(true))
                        }
                    >
                        <span className="">Join</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
