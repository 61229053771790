import {
    ChangeEvent,
    forwardRef,
    ForwardedRef,
    useRef,
    useEffect,
} from "react";
import Form from "react-bootstrap/Form";

type TInputProps = {
    error?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    maxLength?: number;
    placeholder?: string;
    value?: string;
    className?: string;
    disabled?: boolean;
    label?: string;
    type?: string;
    asTextArea?: boolean;
    rows?: number;
    focusOnError?: boolean;
    autoFocus?: boolean;
    autoComplete?: string;
};

const Input = forwardRef((props: TInputProps, ref: ForwardedRef<any>) => {
    const inputRef = useRef<any>(null);
    useEffect(() => {
        if (props.focusOnError && props.error) {
            inputRef?.current?.focus?.();
        }
    }, [props.error, props.focusOnError]);
    return (
        <>
            {props.label ? <Form.Label>{props.label}</Form.Label> : undefined}
            <Form.Control
                autoFocus={props.autoFocus}
                ref={(r: any) => {
                    inputRef.current = r;
                    if (typeof ref === "function") {
                        ref(r);
                    } else {
                        if (ref) {
                            ref.current = r;
                        }
                    }
                }}
                as={props.asTextArea ? "textarea" : undefined}
                rows={props.rows}
                className={props.className}
                value={props.value}
                type={props.type}
                autoComplete={props.autoComplete}
                isInvalid={props.error ? true : false}
                disabled={props.disabled}
                maxLength={props.maxLength}
                onChange={props.onChange}
                placeholder={props.placeholder}
            />
            {props.error ? (
                <Form.Control.Feedback type="invalid">
                    {props.error}
                </Form.Control.Feedback>
            ) : undefined}
        </>
    );
});
export default Input;
