import { useEffect, useState } from 'react'
import { useNavigate } from "react-router";

// import: assets
// import: styles

// import: constants
import { BLOG, CREATE } from '../../router/routes';
// import: enums
import { UserRole } from "../../enums/roles";

// import: types
// import: utils
// import: data
// import: store
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import { loadBlogs, setBlogPageData } from "../../store/slices/blog-slice";

// import: api
// import: config
// import: components
import LoadingSpinner from "../../components/loading-spinner";
import Button from "react-bootstrap/Button";
import BlogsListItem from "../../components/list-items/blogs-list-item";
import ReactPaginate from 'react-paginate';

function BlogPage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const user = useAppSelector((s) => s.auth.user);

    const fetchingblogs = useAppSelector((s) => s.blog.fetching);
    const blogPosts = useAppSelector((s) => s.blog.pageData.data);
    const [page, setPage] = useState(1);
    const pageSize = parseInt(process.env.React_APP_BLOGS_DATA_LENGTH || "20");
    const count = useAppSelector((s) => s.blog.pageData.totalCount);

    useEffect(() => {
        dispatch(setBlogPageData({
            data: [],
        }));
        dispatch(loadBlogs({
            page,
            size: pageSize,
            site: (user?.roles?.includes(UserRole.Admin) ? "" : "Antigranular")
        }));
    }, [page]);

    return (
        <div className="page-container lg-box">
            <div>
                <div className="page-heading-container">
                    <span className="text-3xl mb-21">Blog</span>
                    <div className="text-xs">
                        The blog is our thought hub where we delve into industry
                        trends, share engaging ideas, and valuable perspectives
                        from renowned industry experts. Explore our insights,
                        empower yourself with knowledge, and stay ahead in the
                        rapidly changing world of data privacy.
                    </div>
                </div>
                {user?.roles?.includes(UserRole.Admin) ? (
                    <Button
                        className="mb-3 rounded-pill"
                        onClick={() => navigate(`/${BLOG}/${CREATE}`)}
                    >
                        Create
                    </Button>
                ) : undefined}
            </div>

            <div className="listing-table-container xs-box">
                {blogPosts && blogPosts?.length > 0 ? (
                    blogPosts.map((blg, idx) => (
                        <BlogsListItem
                            key={blg._id + idx}
                            to={`/${BLOG}/` + blg._id}
                            item={blg}
                        />
                    ))
                ) : blogPosts?.length === 0 && !fetchingblogs ? (
                    <div className="py-5 text-center text-color-tertiary w-100 my-0">
                        No posts yet
                    </div>
                ) : (
                    <></>
                )}
            </div>

            {count && pageSize && count > pageSize ? (
                <div className="pagination-cover justify-content-center align-items-center d-flex w-100">
                    <ReactPaginate
                        breakLabel="..."
                        previousLabel="<"
                        nextLabel=">"
                        onPageChange={(event) => setPage(event.selected + 1)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageCount={Math.ceil(count / pageSize)}
                        className="d-flex pagination-container"
                        renderOnZeroPageCount={null}
                    />
                </div>
            ) : <></>}


            <LoadingSpinner show={fetchingblogs} text={"Getting Blog posts"} />
        </div>
    );
}

export default BlogPage;

