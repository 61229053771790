// import Spinner from "react-bootstrap/Spinner";
import spinnerImg from "../../assets/icons/spinner.png";
type TLoadingSpinnerProps = {
    text?: string;
    show: boolean;
    inLine?: boolean;
};
export default function LoadingSpinner(props: TLoadingSpinnerProps) {
    return props.show ? (
        <div className={props.inLine ? "" : "overlay"}>
            {/* <Spinner animation="border" variant="primary" /> */}
            <img src={spinnerImg} width={35} height={35} alt="" className="rotating" />
            {props.text ? <p className="mt-4 text-color-secondary">{props.text}</p> : undefined}
        </div>
    ) : ( <></> );
}