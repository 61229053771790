/* eslint-disable jsx-a11y/anchor-is-valid */
import Button from "react-bootstrap/Button";
import "./index.scss";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import { useEffect, useRef, useState } from "react";
import {
    closeLoginModal,
    login,
    setForgotPasswordModalVisible,
    setRegistrationModalVisible,
} from "../../store/slices/auth-slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
// import Spinner from "react-bootstrap/Spinner";
// import { faGoogle, faGithub } from "@fortawesome/free-brands-svg-icons";
import { HandleError } from "../../errors/handler";
import Input from "../input/input";
import { loadCompetitions } from "../../store/slices/competition-slice";
import { loadNotebooks } from "../../store/slices/notebook-slice";
import { loadDatasets } from "../../store/slices/dataset-slice";
import { GIT_AUTH_URL, GOOGLE_AUTH_URL } from "../../configs/constants";
import { AuthProvider } from "../../enums/auth-provider";
import googleIcon from "../../assets/logos/google.png";
import gitIcon from "../../assets/logos/github.png";
import { executeV3Captcha } from "../../utils/captcha-methods";
// import { useLocation } from "react-router-dom";
import LoadingSpinner from "../loading-spinner";

export default function LoginModal() {
    //to reload the data after login
    // const location = useLocation();
    const state = useAppSelector((s) => s);
    const dispatch = useAppDispatch();
    const logingIn = useAppSelector((s) => s.auth.logingin);
    const show = useAppSelector((s) => s.auth.showLoginModal);
    // const [selectedMedium, setSelectedMedium] = useState<AuthProvider | null>(
    //     null
    // );
    const [loading, setLoading] = useState<boolean>(false);
    const [formValidated, setFormValidated] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<any>({});
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            setFormValidated(false);
            setFormErrors({});
            const form = e.currentTarget;
            e.preventDefault();
            e.stopPropagation();
            if (form.checkValidity() === false) {
                setFormValidated(true);
                return;
            }
            const errors: any = {};
            if (!email) {
                errors.email = "Please enter email.";
            }
            if (!password) {
                errors.password = "Please enter password.";
            }
            if (Object.keys(errors)?.length) {
                setFormErrors(errors);
                return;
            }
            const token = await executeV3Captcha("login");

            await dispatch(
                login({
                    email: email,
                    password,
                    captcha: token ?? "",
                })
            ).unwrap();

            if (state.competition.pageData.data?.length) {
                dispatch(
                    loadCompetitions({
                        page: 1,
                        size: parseInt(
                            process.env.React_APP_COMPETITIONS_DATA_LENGTH ||
                                "10"
                        ),
                    })
                );
            }
            if (state.notebook.approvedData.data?.length) {
                dispatch(loadNotebooks({page: 1, size : parseInt(process.env.React_APP_NOTEBOOKS_DATA_LENGTH || "20")}));
            }
            if (state.dataset.pageData.data?.length) {
                dispatch(loadDatasets({page: 1, size : parseInt(process.env.React_APP_DATASETS_DATA_LENGTH || "20")}));
            }
            dispatch(closeLoginModal());
        } catch (error) {
            setFormErrors(HandleError(error));
        }
    };

    // useEffect(() => {
    //     if(show === true){
    //         localStorage.removeItem( "DS_WLC");
    //         localStorage.removeItem( "NB_WLC");
    //         localStorage.removeItem( "COMP_WLC");
    //     }
    // }, [show]);

    const onMediumSelect = (provider: AuthProvider) => {
        if (provider === AuthProvider.Github) {
            // localStorage.setItem("ROUT_TO_PATH", window.location.pathname)
            setLoading(true);
            // console.log(GIT_AUTH_URL);
            window.location.href = GIT_AUTH_URL;
        } else if (provider === AuthProvider.Google) {
            // localStorage.setItem("ROUT_TO_PATH", window.location.pathname)
            setLoading(true);
            // console.log(GOOGLE_AUTH_URL);
            window.location.href = GOOGLE_AUTH_URL;
        } else {
            if(localStorage.getItem("ROUT_TO_PATH")) localStorage.removeItem("ROUT_TO_PATH");
        }
    };

    return (
        <Modal
            show={show}
            onHide={() => dispatch(closeLoginModal())}
            centered
            dialogClassName="login-modal"
        >
            <Modal.Header closeButton>
                <div>Welcome Back</div>
            </Modal.Header>
            <Modal.Body className="">
            {loading ? (
                    <div className="text-center text-color-secondary">
                        <LoadingSpinner show={loading} inLine />
                        <p className="mt-3"> Redirecting to authentication page </p>
                    </div>
                ) : <>
                    <Form
                        validated={formValidated}
                        noValidate
                        onSubmit={submit}
                        className="text-color-secondary"
                    >
                        <fieldset disabled={logingIn}>
                            <div className="form-input-fields text-xs text-color-secondary">
                                <Form.Group>
                                    <Input
                                        autoFocus
                                        value={email}
                                        label="Email"
                                        placeholder="Your email"
                                        maxLength={100}
                                        onChange={(e) => {
                                            if (formErrors["email"]) {
                                                delete formErrors?.email;
                                                setFormErrors(formErrors);
                                            }
                                            setEmail(e.target.value);
                                        }}
                                        error={formErrors["email"]}
                                    />
                                </Form.Group>
                                <Form.Group className="mt-2 pt-1">
                                    <Input
                                        label="Password"
                                        placeholder="Your password"
                                        type="password"
                                        error={formErrors.password}
                                        onChange={(e) => {
                                            if (formErrors["password"]) {
                                                delete formErrors.password;
                                                setFormErrors(formErrors);
                                            }
                                            setPassword(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                                
                                <Form.Group className="mt-2 pt-1">
                                    <div id="captcha_container"></div>
                                </Form.Group>

                                <Form.Group className={formErrors[""] && "mt-3"}>
                                    {formErrors[""] ? (
                                        <label className="text-danger">
                                            {formErrors[""]}
                                        </label>
                                    ) : undefined}

                                    {formErrors["captcha"] ? (
                                        <label className="text-danger">
                                            {formErrors["captcha"]}
                                        </label>
                                    ) : undefined}
                                </Form.Group>
                            </div>

                            <Button disabled={logingIn} type="submit" variant="primary" className="rounded-pill mx-auto">
                                {logingIn ? (
                                    <span className="pe-1">
                                        <FontAwesomeIcon spin icon={faSpinner} className="me-2"/>Authorizing
                                    </span>
                                ) : (
                                    "Sign In"
                                )}
                            </Button>
                        </fieldset>
                    </Form>
                    
                    <div className="form-options text-color-secondary">
                        <div className="separator">
                            <hr className="w-100 me-2"/>
                            <span>or</span>
                            <hr className="w-100 ms-2"/>
                        </div>
                        <div className="form-external-brand-links text-xs">
                            <div className="me-4">
                                <div className="brand-image-cover">
                                    <img alt="" className="feature-icon" src={googleIcon}
                                        onClick={() => onMediumSelect(AuthProvider.Google)}
                                    />
                                </div>
                                <p className="mt-2 mb-0">Google</p>
                            </div>
                            <div>
                                <div className="brand-image-cover">
                                    <img alt="" className="feature-icon" src={gitIcon} 
                                        onClick={() => onMediumSelect(AuthProvider.Github)}
                                    />
                                </div>
                                <p className="mt-2 mb-0">Github</p>
                            </div>
                        </div>
                    </div>

                    <p className="text-xs text-center text-color-secondary">
                        Forgot your password?
                        <a  className="actions-link"
                            href="#"
                            onClick={() => {
                                dispatch(closeLoginModal());
                                setTimeout(() => {
                                    dispatch(setForgotPasswordModalVisible(true));
                                }, 200);
                            }}
                        >Recover</a>
                    </p>
                    <p className="text-xs text-center text-color-secondary">
                        Don't have an account?
                        <a  className="actions-link"
                            href="#"
                            onClick={() => {
                                dispatch(closeLoginModal());
                                setTimeout(() => {
                                    dispatch(setRegistrationModalVisible(true));
                                }, 200);
                            }}
                        >Register</a>
                    </p>
                </>
            }
            </Modal.Body>
        </Modal>
    );
}
